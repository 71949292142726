import React from 'react'
import { PulseLoader } from 'react-spinners';

function LoaderAnimation({ loading, size, color }) {
    const loaderOverlayStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        display: loading ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 99999
    };

    const loaderStyle = {
        pointerEvents: 'none'
    };
    return (
        <div style={loaderOverlayStyle}>
            <div style={loaderStyle}>
                <PulseLoader loading={loading} size={size} color="#743D90" />
            </div>
        </div>
    )
}

export default LoaderAnimation