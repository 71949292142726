export const environment = {
    // production
    // production: true,
    // production_domain: 'https://api-rama.numeddatacenter.com'

    // Uat
    production: false,
    development_domain: 'https://api-rama-uat.numeddatacenter.com'
}

