import { Box, Button, Container, IconButton, Link, Typography } from '@mui/material'
import React from 'react'
import { InputTextNormal } from '../../components/InputText'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import dayjs from 'dayjs';
import buddhistEra from "dayjs/plugin/buddhistEra";
import 'dayjs/locale/th';
import { useEffect } from 'react';
import { useState } from 'react';
import ModalSharedConference from '../../components/ModalController/ModalSharedConference'
import * as api from '../../services/ApiRecordPatient'
import LoaderAnimation from '../../components/LoaderAnimation'
import { notifySuccess, notifyInfo, notifyError, notifyWarning } from '../../components/NotifyToast'
import QRCode from 'react-qr-code';

dayjs.locale('th');
dayjs.extend(buddhistEra);

function ContactConference({ pid }) {

    const [loading, setLoading] = useState(false)

    const [openShared, setOpenShared] = useState(false)
    const [currentTime, setCurrentTime] = useState(dayjs().format('HH:mm:ss'));

    const [name, setName] = useState(localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name'))
    const [dataConferrence, setDataConferrence] = useState({})

    const [check, setCheck] = useState('')
    const [body, setBody] = useState([])

    const [value, setValue] = useState('')

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(dayjs().format('HH:mm:ss'));
        }, 1000); // Update the time every second (1000 milliseconds)

        // Clear the interval when the component is unmounted
        return () => clearInterval(timer);
    }, []);

    useEffect(() => {
        // console.log(dayjs().format('LL'))
        // console.log(dayjs().format('HH:mm:ss'))
        console.log(pid)
        createConferrenceRoom()
    }, [])

    const DateLongTH = () => {
        dayjs.locale("th");
        return dayjs().format("dddd D MMMM BBBB");
    };

    const createConferrenceRoom = async () => {
        setLoading(true)
        let res = await api.createConferrenceRoom()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataConferrence(res['data']['data'])
                setCheck('checkData')
                setValue(res['data']['data']['url'])
            } else {
                console.log(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const clickCreateConferrence = (url) => {
        window.open(url, "_system")
    }

    const clickShareConferrence = () => {
        let body = {
            "meetingID": dataConferrence['meetingID'],
            "platform": "rama-app",
            "pid": pid,
            "url_meeting": dataConferrence['url']
        }
        setOpenShared(true)
        setBody(body)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Container component="main">
                {openShared && <ModalSharedConference
                    onClose={() => { setOpenShared(!openShared) }}
                    // urlMeeting={dataConferrence['url']}
                    data={body}
                />}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: 'auto',
                    }}
                >
                    <Box
                        sx={{
                            borderRadius: '15px',
                            display: "flex",
                            flexDirection: "column",
                            width: '100%',
                        }}
                    >
                        <div style={{
                            border: '1px solid #E1E3E9',
                            borderRadius: '15px',
                        }}>
                            <Box
                                sx={{
                                    borderRadius: '15px 15px 0px 0px',
                                    backgroundColor: '#e1f4eb',
                                    padding: '1rem',
                                    width: '100%' // Add this line to make the box full width
                                }}
                            >
                                <div style={{
                                    padding: '15px'
                                }}>
                                    <Typography variant="h3" sx={{
                                        '@media (max-width: 950px)': {
                                            fontSize: '40px',
                                            fontWeight: 'bold'
                                        },
                                    }} align='center'>
                                        {currentTime}
                                    </Typography>
                                    <Typography variant="body2" align='center'>
                                        วัน {DateLongTH()}
                                    </Typography>
                                </div>
                            </Box>

                            <div style={{
                                padding: '1rem'
                            }}>
                                <div style={{
                                    margin: '10px 0px 20px 0px'
                                }}>
                                    <Typography sx={{ fontSize: '18px', my: '0.5rem', fontWeight: 'bold' }}>
                                        ชื่อผู้ใช้งาน
                                    </Typography>
                                    <InputTextNormal
                                        value={name}
                                        readOnly={true}
                                    />
                                </div>

                                <div style={{
                                    display: 'grid',
                                    gap: '10px'
                                }}>
                                    <Button
                                        variant="contained"
                                        disabled={check === '' ? true : null}
                                        onClick={() => { clickCreateConferrence(dataConferrence['url']) }}
                                    >เข้าร่วม</Button>

                                    <Button
                                        variant="contained"
                                        onClick={() => { clickShareConferrence() }}
                                    >แชร์ลิ้งค์ไปยังผู้ป่วย</Button>
                                </div>
                            </div>
                        </div>

                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            gap: '20px',
                            alignItems: 'center',
                            marginTop: 10
                        }}>
                            <div
                                onClick={() => {
                                    try {
                                        navigator.clipboard.writeText(dataConferrence['url'])
                                        notifySuccess('คัดลอกสำเร็จ')
                                    } catch (error) {
                                        console.log(error)
                                        notifyError('คัดลอกไม่สำเร็จ')
                                    }
                                }}
                                style={{
                                    cursor: 'pointer',
                                    display: 'flex',
                                    color: check ? '#743D90' : '#747676',
                                    margin: '1rem 0'
                                }}
                            >
                                <ContentCopyIcon />
                                <div>Copy Link</div>
                            </div>
                        </div>

                        <Box sx={{
                            display: 'grid',
                            justifyItems: 'center',
                            gridGap: 10
                        }} >
                            <Box sx={{
                                width: 150,
                                '@media (max-width: 800px)': {
                                    width: 100
                                }
                            }}>
                                <QRCode
                                    value={value}
                                    size={'100%'}
                                />
                            </Box>
                            <div>สแกน Qr Code เพื่อเข้าร่วม</div>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>

    )
}

export default ContactConference