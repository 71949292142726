import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import avatar from '../assets/img/post_img.svg'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReplyIcon from '@mui/icons-material/Reply';
import RepeatIcon from '@mui/icons-material/Repeat';
import StarBorderIcon from '@mui/icons-material/StarBorder';

const headerContent = {
    display:'flex',
    justifyContent:'space-between'
}
const userPost = {
    display:'flex',
    alignItems:{xl: 'center',lg: 'center',md: 'center',sm: 'start',xs: 'start'},
    flexDirection: {xl: 'row',lg: 'row',md: 'row',sm: 'column',xs: 'column'},
    gridGap:'1rem'
}
const content = {
    margin:'1rem 0'
}
const contentButton = {
    display:'flex',
    justifyContent:'space-between'
}
function PostContent() {

  return (
    <Box>
        <Box sx={headerContent}>
            <Box sx={userPost}>
                <img src={avatar} style={{maxWidth:'45px',maxHeight:'45px'}}></img>
                <Typography variant='p' component='p' color='#000'>สมศักดิ์ ใจดี</Typography>
                <Typography variant='p' component='p' color='#7C7C7C'>5 วันที่แล้ว @admin</Typography>
            </Box>
            <IconButton sx={{width:'45px', height:'45px'}}>
                <MoreVertIcon/>
            </IconButton>
        </Box>
        <Box sx={content}>
            Content
        </Box>
        <Box sx={contentButton}>
            <IconButton>
                <ReplyIcon/>
            </IconButton>
            <IconButton>
                <RepeatIcon/>
            </IconButton>
            <IconButton>
                <StarBorderIcon/>
            </IconButton>
        </Box>
        <hr style={{ margin:'1rem 0', border: '0.5px solid #E3E3E5'}}/>
    </Box>
  )
}

export default PostContent