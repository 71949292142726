import { Box, FormControl, MenuItem, Select, Button, Typography, FormControlLabel, Switch, Tabs, Tab, TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Stack, Pagination } from "@mui/material";
import React, { useState, useEffect } from "react";
import { styled } from '@mui/material/styles';
import * as api from '../../../services/ApiConsultDoctor'
import LoaderAnimation from '../../../components/LoaderAnimation'
import moment from "moment";
import { notifyError, notifyInfo, notifySuccess } from "../../../components/NotifyToast";
import { io } from 'socket.io-client';

import { environment } from '../../../config.env'

import { useDispatch } from "react-redux";
import { logout } from './../../../store/authSlice';

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain


export default function DoctorCallsQueue() {

    const dispatch = useDispatch()

    const [socket, setSocket] = useState(null)
    const [topic, setTopic] = useState(null)
    const [loading, setLoading] = useState(false)

    const [roomList, setRoomList] = useState([])
    const [department, setDepartment] = useState('')
    const [optionDepartment, setOptionDepartment] = useState([])
    const [location, setLocation] = useState('')
    const [optionLocation, setOptionLocation] = useState([])
    const [room, setRoom] = useState('')
    const [optionRoom, setOptionRoom] = useState([])

    const [currentQueue, setCurrentQueue] = useState(null)
    const [dataAllQueue, setDataAllQueue] = useState([])
    const [dataCallQueue, setDataCallQueue] = useState([])

    // const [selectedClinic, setSelectedClinic] = useState(null)
    // const [selectedLocation, setSelectedLocation] = useState(null)
    // const [selectedRoom, setSelectedRoom] = useState(null)


    const [checked, setChecked] = useState(false)

    const [value, setValue] = useState(0)

    useEffect(() => {
        const socket = io(ENDPOINT_DOMAIN + '/', {
            path: "/socket/socket.io",
            autoConnect: true,
            reconnection: false,
            transports: ["websocket"]
        })


        console.log(socket)

        setSocket(socket)


        socket.on("connect_error", () => {
            console.log('on : connect_error');
            socket.connect();
        });

        socket.io.on("reconnect_attempt", () => {
            console.log('on : reconnect_attempt');
        });

        socket.io.on("reconnect", () => {
            console.log('on : reconnect');
        });

        socket.on("subscribed", () => {
            console.log('on : subscribed');

        });


        socket.on("unsubscribed", () => {
            console.log('on : unsubscribed');
        });

        socket.on("call_queue", (data) => {
            console.log('on : call_queue ', data);

            // if (data['appointment_next_queue_list'].length > 0) {
            //     console.log(data['appointment_next_queue_list'])
            // }
            if (data['appointment_next_queue_list']?.length > 0) {

                let data_realtime = []
                let data_emergency = []

                let allList = data['appointment_next_queue_list']
                for (let i = 0; i < allList.length; i++) {
                    if (!allList[i].is_call) {
                        if (allList[i].is_emergency === true) {
                            data_emergency.push(allList[i])
                        }
                        else {
                            data_realtime.push(allList[i])
                        }
                    }
                }

                data_realtime = data_emergency.concat(data_realtime)
                setDataCallQueue(data_realtime)
            } else {
                setDataCallQueue([])
            }

            if (data['current_date_queue']) {
                setCurrentQueue(data['current_date_queue'])
            }

        });

        socket.on("call_queue_direct", (data) => {
            console.log('on : call_queue_direct ', data);

            if (data['current_queue']) {
                setCurrentQueue(prevForm => ({
                    ...prevForm,
                    current_queue: data['current_queue'],
                }));
            }
        });

        socket.on("update_queue", (data) => {
            console.log('on : update_queue ', data);

            if (data['current_date_queue']) {
                setCurrentQueue(data['current_date_queue'])
            }

            if (data['appointment_assign_room_list']?.length > 0) {
                let data_realtime = []
                let data_emergency = []
                let allList = data['appointment_assign_room_list']
                for (let i = 0; i < allList.length; i++) {
                    if (!allList[i].is_call) {
                        if (allList[i].is_emergency === true) {
                            data_emergency.push(allList[i])
                        }
                        else {
                            data_realtime.push(allList[i])
                        }
                    }
                }

                data_realtime = data_emergency.concat(data_realtime)
                setDataAllQueue(data_realtime)
            }

            if (data['appointment_list']?.length > 0) {
                setDataAllQueue(data['appointment_list'])

                let data_realtime = []
                let data_emergency = []

                let allList = data['appointment_list']
                for (let i = 0; i < allList.length; i++) {
                    if (!allList[i].is_call) {
                        if (allList[i].is_emergency === true) {
                            data_emergency.push(allList[i])
                        }
                        else {
                            data_realtime.push(allList[i])
                        }
                    }
                }

                data_realtime = data_emergency.concat(data_realtime)
                setDataCallQueue(data_realtime)
            } else {
                setDataCallQueue([])
            }

        });

        socket.on("update_cancel_queue", (data) => {
            console.log('on : update_cancel_queue ', data);

            if (data['current_date_queue']) {
                setCurrentQueue(data['current_date_queue'])
            }

            if (data['appointment_list']?.length > 0) {
                setDataAllQueue(data['appointment_list'])

                let data_realtime = []
                let data_emergency = []

                let allList = data['appointment_list']
                for (let i = 0; i < allList.length; i++) {
                    if (!allList[i].is_call) {
                        if (allList[i].is_emergency === true) {
                            data_emergency.push(allList[i])
                        }
                        else {
                            data_realtime.push(allList[i])
                        }
                    }
                }

                data_realtime = data_emergency.concat(data_realtime)
                setDataCallQueue(data_realtime)
            } else {
                setCancelQueue([])
            }
        });

        socket.on("update_pause", (data) => {
            console.log('on : update_pause ', data);


            if (data.is_pause == false) {
                notifyInfo('พักตรวจ')
                return
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [])

    useEffect(() => {
        if (topic) {
            socket.emit("unsubscribe", topic);

            socket.emit("subscribe", topic);
        }
        console.log(topic)

    }, [topic])

    useEffect(() => {
        console.log(currentQueue?.['current_queue'])
    }, [currentQueue])

    useEffect(() => {
        // const fetchData = async () => {
        //     setLoading(true);

        //     try {
        //         const resClinicRoom = await api.getClinicAndRoom()
        //         if (resClinicRoom && resClinicRoom['data']['code'] === 200) {
        //             setRoomList(res['data']['room_list'])
        //             setOptionDepartment(res['data']['clinic_list'])
        //         } else {
        //             notifyError(resClinicRoom['data']['message']);
        //         }
        //     } catch (error) {
        //         notifyError('ไม่ตอบสนอง');
        //     } finally {
        //         setLoading(false);
        //     }
        // };

        // fetchData();
    }, [])

    useEffect(() => {
        getClinicAndRoom()
    }, [])

    useEffect(() => {
        getLocation()
        getRoom()
    }, [department])

    useEffect(() => {
        if (department) {
            getCurrentDateQueue()
        }
    }, [room])

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    const getClinicAndRoom = async () => {
        setLoading(true)
        let res = await api.getClinicAndRoom()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setRoomList(res['data']['room_list'])
                setOptionDepartment(res['data']['clinic_list'])
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const locationList = []
    const getLocation = () => {
        for (let i = 0; i < roomList.length; i++) {
            if (roomList[i]['clinic_code'] === department) {
                const location = roomList[i]['location'];

                if (!locationList.includes(location)) {
                    locationList.push(location);
                }
            }
        }
        setOptionLocation(locationList.map(i => {
            return {
                key: i,
                value: i
            }
        }))
    }

    const roomAll = []
    const getRoom = () => {
        for (let i = 0; i < roomList.length; i++) {
            if (roomList[i]['clinic_code'] == department) {
                roomAll.push(roomList[i])
            }
        }
        setOptionRoom(roomAll.map(i => {
            return {
                key: i['room_name'],
                conference_link: i['conference_link']
            }
        }))
    }

    const clickConference = () => {
        // console.log(room)
        // console.log(optionRoom)
        const url = []
        for (let i = 0; i < optionRoom.length; i++) {
            if (optionRoom[i]['key'] == room) {
                url.push(optionRoom[i]['conference_link'])
            }
        }
        window.open(url, '_system')
    }

    const getCurrentDateQueue = async () => {
        setLoading(true)
        let res = await api.getCurrentDateQueue(department, location, room)
        console.log('getCurrentDateQueue :', res)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setCurrentQueue(res['data']['current_date_queue'])
                setDataAllQueue(res['data']['appointment_list'])

                setTopic(localStorage.getItem('hospital_code') + '/' + department + '/' + location + '/' + room)

                let list = []
                let allList = res['data']['appointment_list']
                for (let i = 0; i < allList.length; i++) {
                    if (allList[i]['is_call'] === false) {
                        list.push(allList[i])
                    }
                }
                setDataCallQueue(list)

            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const callDirectQueue = async (id) => {
        setLoading(true)
        let res = await api.callDirectQueue(id)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('เรียกคิวสำเร็จ')
                // getCurrentDateQueue()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const setCancelQueue = async (id) => {
        setLoading(true)
        let res = await api.setCancelQueue(id)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('ยกเลิกคิวสำเร็จ')
                // getCurrentDateQueue()
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const setRoomPause = async () => {
        setLoading(true)
        let res = await api.setRoomPause(checked, department, location, room)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('พักตรวจ')
                // getCurrentDateQueue()
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const callNextQueue = async () => {
        setLoading(true)
        let res = await api.callNextQueue(department, location, room)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('เรียกคิวถัดไปสำเร็จ')
                // getCurrentDateQueue()
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const switchHandlerPause = (event) => {
        console.log(event.target.checked)
        setChecked(event.target.checked)
        setRoomPause()
    }

    const clickCallRecursive = (id) => { //เรียกซ้ำ
        callDirectQueue(id)
    }

    const clickNextQueue = () => { //คิวถัดไป
        if (!department) {
            notifyInfo('กรุณาเลือกรหัสแผนก')
            return
        }
        if (!location) {
            notifyInfo('กรุณาเลือกสถานที่')
            return
        }
        if (!room) {
            notifyInfo('กรุณาเลือกห้อง')
            return
        }
        callNextQueue()
    }

    const clickCallQueue = (id) => { //เรียกคิว
        callDirectQueue(id)
    }

    const clickCancelQueue = (id) => { //ยกเลิกคิว
        setCancelQueue(id)
    }

    const groupCodeLine = (clinic_code, queue_code) => {
        let queue_str = String(queue_code);

        if (queue_str.length < 2) {
            queue_str = '000' + queue_str;
        } else if (queue_str.length < 3) {
            queue_str = '00' + queue_str;
        } else if (queue_str.length < 4) {
            queue_str = '0' + queue_str;
        }

        return clinic_code + queue_str;
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                color: '#743D90',
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1,
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14
                }
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontWeight: 'bold' }}>Filters</div>
                    <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 20 }}>
                        <Typography>พักตรวจ</Typography>
                        <FormControlLabel
                            control={
                                <IOSSwitch
                                    sx={{ m: 1 }}
                                    checked={checked}
                                    onChange={switchHandlerPause}
                                />
                            }
                        />
                    </Box>
                </Box>

                <Box sx={{
                    padding: '10px 40px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: 30,
                    alignItems: 'flex-end',
                    fontSize: 18,

                    '@media (max-width: 1023px)': {
                        padding: '5px 30px',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: 20,
                        fontSize: 16,
                    },
                    '@media (max-width: 800px)': {
                        padding: '5px 20px',
                        gridTemplateColumns: '1fr',
                        gridGap: 10,
                        fontSize: 14,
                    }
                }}>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold',
                        }}>
                            เลือกรหัสแผนก
                        </Typography>
                        <Select
                            size="small"
                            value={department || ' '}
                            disabled={optionDepartment.length > 0 ? false : true}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setDepartment(value)
                            }}
                        >
                            <MenuItem value=" ">เลือกรหัสแผนก</MenuItem>
                            {optionDepartment.map(i => (
                                <MenuItem
                                    key={i['clinic_code']}
                                    value={i['clinic_code']}
                                >{i['clinic_code'] + ' (' + i['department_name'] + ')'}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold'
                        }}>
                            เลือกสถานที่
                        </Typography>
                        <Select
                            size="small"
                            value={location || ' '}
                            disabled={!department || department === ' ' ? true : false}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setLocation(value)
                            }}
                        >
                            <MenuItem value=" ">เลือกสถานที่</MenuItem>
                            {optionLocation.map(i => (
                                <MenuItem
                                    key={i['key']}
                                    value={i['key']}
                                >{i['value']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold'
                        }}>
                            เลือกห้อง
                        </Typography>
                        <Select
                            size="small"
                            value={room}
                            disabled={!location || location === ' ' ? true : false}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setRoom(value)
                            }}
                        >
                            <MenuItem value=" ">เลือกห้อง</MenuItem>
                            {optionRoom.map(i => (
                                <MenuItem
                                    key={i['key']}
                                    value={i['key']}
                                >{i['key']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Button
                            variant="contained"
                            disabled={room === '' ? true : null}
                            onClick={() => { clickConference() }}
                        >Conference</Button>
                    </FormControl>
                </Box>
            </Box>

            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gridGap: 4,
                marginTop: 3
            }}>
                <Box sx={{
                    backgroundColor: '#D0EAF9',
                    borderRadius: 2,
                    padding: 2,
                    '@media (max-width: 800px)': {
                        padding: 1
                    }
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 120,
                        color: '#222222',
                        '@media (max-width: 800px)': {
                            fontSize: 30,
                        }
                    }}>{currentQueue?.['appointment_amount'] ? currentQueue['appointment_amount'] : 0}</Typography>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 40,
                        color: '#222222',
                        '@media (max-width: 800px)': {
                            fontSize: 14,
                        }
                    }}>จำนวนทั้งหมด</Typography>
                </Box>

                <Box sx={{
                    backgroundColor: '#F3EDF7',
                    borderRadius: 2,
                    padding: 2,
                    '@media (max-width: 800px)': {
                        padding: 1
                    }
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 120,
                        color: '#222222',
                        '@media (max-width: 800px)': {
                            fontSize: 30,
                        }
                    }}>{currentQueue?.['appointment_called'] ? currentQueue['appointment_called'] : 0}</Typography>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 40,
                        color: '#222222',
                        '@media (max-width: 800px)': {
                            fontSize: 14,
                        }
                    }}>จำนวนที่เรียกแล้ว</Typography>
                </Box>

                <Box sx={{
                    backgroundColor: '#FCF5BC',
                    borderRadius: 2,
                    padding: 2,
                    '@media (max-width: 800px)': {
                        padding: 1
                    }
                }}>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 120,
                        color: '#222222',
                        '@media (max-width: 800px)': {
                            fontSize: 30,
                        }
                    }}>{currentQueue?.['appointment_wait_call'] ? currentQueue['appointment_wait_call'] : 0}</Typography>
                    <Typography sx={{
                        fontWeight: 600,
                        fontSize: 40,
                        color: '#222222',
                        '@media (max-width: 800px)': {
                            fontSize: 14,
                        }
                    }}>จำนวนที่รอเรียก</Typography>
                </Box>
            </Box>

            {currentQueue?.['current_queue'] ?
                <>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E9',
                        borderRadius: 2,
                        marginTop: 3,
                        padding: 5,
                        display: 'grid',
                        justifyItems: 'center',
                        '@media (max-width: 1023px)': {
                            marginTop: 2,
                            padding: 1,
                        },
                        '@media (max-width: 800px)': {
                            marginTop: 1,
                            padding: 1,
                        }
                    }}>
                        <Typography sx={{
                            color: '#222222',
                            fontWeight: 600,
                            fontSize: 40,
                            '@media (max-width: 800px)': {
                                fontSize: 22,
                            }
                        }}>คิวปัจจุบัน</Typography>
                        <Typography sx={{
                            color: '#222222',
                            fontWeight: 500,
                            my: '10px',
                            fontSize: 36,
                            '@media (max-width: 800px)': {
                                fontSize: 20,
                            }
                        }}>
                            {currentQueue['current_queue']?.['fname'] + ' ' + currentQueue['current_queue']['lname']}
                        </Typography>

                        <FormControl sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gridGap: 20,
                            width: 300,
                            '@media (max-width: 800px)': {
                                width: 200,
                            }
                        }}>
                            <Button sx={{
                                fontSize: 20,
                                '@media (max-width: 800px)': {
                                    fontSize: 14,
                                }
                            }} variant="contained" onClick={() => { clickCallRecursive(currentQueue['current_queue']['_id']) }}>เรียกซ้ำ</Button>


                            <Button
                                disabled={currentQueue?.['appointment_wait_call'] ? currentQueue['appointment_wait_call'] > 0 ? false : true : true} sx={{
                                    fontSize: 20,
                                    '@media (max-width: 800px)': {
                                        fontSize: 14,
                                    }
                                }} variant="contained" onClick={() => { clickNextQueue() }}>คิวถัดไป</Button>
                        </FormControl>
                    </Box>
                </>
                : ''
            }

            {!currentQueue?.['current_queue'] ?
                <>
                    <Box sx={{
                        backgroundColor: '#FFFFFF',
                        border: '1px solid #E1E3E9',
                        borderRadius: 2,
                        marginTop: 3,
                        padding: 5,
                        display: 'grid',
                        justifyItems: 'center',
                        '@media (max-width: 1023px)': {
                            marginTop: 2,
                            padding: 1,
                        },
                        '@media (max-width: 800px)': {
                            marginTop: 1,
                            padding: 1,
                        }
                    }}>
                        <Button
                            disabled={currentQueue?.['appointment_wait_call'] ? currentQueue['appointment_wait_call'] > 0 ? false : true : true} sx={{
                                fontSize: 20,
                                '@media (max-width: 800px)': {
                                    fontSize: 14,
                                }
                            }} variant="contained" onClick={() => { clickNextQueue() }}>คิวถัดไป</Button>
                    </Box>
                </>
                : ''
            }


            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                color: '#743D90',
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1,
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14
                }
            }}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: '#ffffff',
                }}>
                    <Tabs variant="fullWidth" sx={{ width: 'auto' }} value={value} onChange={handleChangeTabs} centered>
                        <Tab sx={{
                            width: '100%',
                            fontSize: 18,
                            fontWeight: 'bold',
                            '@media (max-width: 800px)': {
                                width: '50%',
                                fontSize: 14
                            }
                        }} label="คิวรอเรียก" />
                        <Tab sx={{
                            width: '100%',
                            fontSize: 18,
                            fontWeight: 'bold',
                            '@media (max-width: 800px)': {
                                width: '50%',
                                fontSize: 14
                            }
                        }} label="คิวทั้งหมด" />
                    </Tabs>
                    {/* คิวรอเรียก*/}
                    {value === 0 ?
                        <>
                            <TableContainer>
                                <Table sx={{ marginTop: 3, minWidth: '1000px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>#</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เวลาส่งเข้าห้องตรวจ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เวลาซักประวัติ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>รหัสนำทาง</TableCell>
                                            {/* <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>HN</TableCell> */}
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>ชื่อ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เรียกคิว</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>ยกเลิกคิว</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {dataCallQueue.map((i, idx) => (
                                        <TableBody key={idx}>
                                            <TableRow>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{moment(i['assign_room_timestamp']).format('HH:mm') + ' น.'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['history_inquiry_start'] ? i['history_inquiry_start'] + ' น.' : i['appointment_time'] + ' น.'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['queue'] ? groupCodeLine(i['clinic_code'], i['queue']) : '-'}</TableCell>
                                                {/* <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['hn'] === null ? '-' : i['hn']}</TableCell> */}
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['fname'] + ' ' + i['lname']}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>
                                                    <Button variant="contained" onClick={() => { clickCallQueue(i['_id']) }}>เรียก</Button>
                                                </TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>
                                                    <Button variant="outlined" onClick={() => { clickCancelQueue(i['_id']) }}>ยกเลิก</Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}

                                </Table>
                            </TableContainer>
                        </>
                        : null
                    }

                    {value === 1 ?
                        <>
                            <TableContainer>
                                <Table sx={{ marginTop: 3, minWidth: '1000px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>#</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>เวลาส่งเข้าห้องตรวจ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>เวลาซักประวัติ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>รหัสนำทาง</TableCell>
                                            {/* <TableCell style={{ color: '#743D90', textAlign: 'center' }}>HN</TableCell> */}
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>ชื่อ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>เรียกคิว</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {dataAllQueue.map((i, idx) => (
                                        <TableBody key={idx}>
                                            <TableRow>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{moment(i['assign_room_timestamp']).format('HH:mm') + ' น.'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['history_inquiry_start'] ? i['history_inquiry_start'] + ' น.' : i['appointment_time'] + ' น.'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['queue'] ? groupCodeLine(i['clinic_code'], i['queue']) : '-'}</TableCell>
                                                {/* <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['hn'] === null ? '-' : i['hn']}</TableCell> */}
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['fname'] + ' ' + i['lname']}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>
                                                    <Button variant="contained" onClick={() => { clickCallQueue(i['_id']) }}>เรียก</Button>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>
                        </>
                        : null
                    }

                </Box>
            </Box>

        </>
    )
}

const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#743D90' : '#743D90',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#743D90',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

