import React,{ useEffect, useState } from 'react'
import { Button, Box, TextField,Typography,Grid,FormControl,Select,MenuItem } from "@mui/material"
import {contentProfile,boxProfile, buttonActive, buttomPrimary,buttomSecondary} from './ProfileCss'
import profileUser from '../../assets/icons/profile-user.svg'
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import * as api from '../../services/public_api'
import { useNavigate } from 'react-router-dom'
import LoaderAnimation from '../../components/LoaderAnimation';
import { notifyError, notifyInfo, notifySuccess, notifyWarning } from '../../components/NotifyToast';


function Profile() {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    const [isEditProfile,setIsEditProfile] = useState(false)
    const [isModalSuccess,setIsModalSuccess] = useState(false)
    const [titleName,setTitleName] = useState([])
    const [department,setDepartment] = useState([])
    const [hospital,setHospital] = useState([])
    const [profile,setProfile] = useState({
        email:'',
        fullname: '',
        title_name: '',
        fname: '',
        lname:'',
        cid:'',
        phone:'',
        department_code:'',
        hospital_code:'',
        hospital_name:'',
        group_role:'',
        group_name:''
    })

    const formEditUser = [
        {
            label :  "อีเมล",
            name : 'email'
        },
        {
            label :  "ชื่อ-นามสกุล",
            name : 'fullname'
        },
        {
            label : 'เลขบัตรประจำตัวประชาชน',
            name : 'cid'
        },
        {
            label : 'เบอร์โทรศัพท์',
            name: 'phone'
        },
        // {
        //     label : 'แผนก',
        //     name: 'department_code'
        // },
        {
            label : 'โรงพยาบาล/สถานพยาบาล',
            name: 'hospital_name'
        },
        {
            label : 'สิทธิการใช้งาน',
            name: 'group_role'
        }
    ]

    useEffect(() => {
        findTitleName()
        findDepartment()
        findHospital()

        setProfile({
            email: localStorage.getItem('email'),
            fullname: ` ${localStorage.getItem('title_name')} ${localStorage.getItem('first_name')} ${localStorage.getItem('last_name')}`,
            title_name: localStorage.getItem('title_name'),
            fname: localStorage.getItem('first_name'),
            lname: localStorage.getItem('last_name'),
            cid: localStorage.getItem('cid'),
            phone: localStorage.getItem('phone'),
            department_code: localStorage.getItem('department_code'),
            hospital_code: localStorage.getItem('hospital_code'),
            hospital_name: localStorage.getItem('hospital_name'),
            group_role: localStorage.getItem('group_role'),
            group_name: localStorage.getItem('group_name')
        })
    },[])

    const findTitleName = async() => {
        setLoading(true)
        let res = await api.findTitleName()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setTitleName(res['data']['data'])
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findDepartment = async() => {
        setLoading(true)
        let res = await api.findDepartment()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setDepartment(res['data']['data'])
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findHospital = async() => {
        setLoading(true)
        let res = await api.findHospital()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                setHospital(res['data']['data'])
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const handleModleSuccess = () => {
        setIsModalSuccess(!isModalSuccess)
    }

    const handleProfileEdit = () => {
        setIsEditProfile(!isEditProfile)
    }

    const handleOnChange = (e) => {
        const {name,value} = e.target
        
        if(name === 'phone'){
            const maxLength = 10;
            const numericRegex = /^[0-9]*$/;
            if (value.length <= maxLength && numericRegex.test(value)) {
                setProfile({ ...profile, [name]: value });
            }
            return
        }
        setProfile({...profile,[name]:value})

    } 
    
    const handleSubmitForm = async() => {
        // console.log(profile)
        if(!profile['title_name'] || profile['title_name'] === ' '){
            return notifyWarning('กรุณากรอก คำนำหน้าชื่อ')
        }
        if(!profile['fname']){
            return notifyWarning('กรุณากรอก ชื่อ')
        }
        if(!profile['lname']){
            return notifyWarning('กรุณากรอก นามสกุล')
        }
        if(!profile['phone']){
            return notifyWarning('กรุณากรอก เบอร์โทรศัพท์')
        }
        if(!profile['department_code']){
            return notifyWarning('กรุณากรอก แผนก')
        }
        setLoading(true)
        let res = await api.editProfile(profile['title_name'],profile['fname'],profile['lname'],profile['phone'],profile['department_code'],)
        setLoading(false)
        if(res){
            console.log("res ====> ",res)
            if(res['data']['code'] === 200){
                await setIsModalSuccess(!isModalSuccess)
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }
    

  return (
    <Box sx={contentProfile}>
        <LoaderAnimation loading={loading} />
         {
            isModalSuccess? (
                <ModalSuccess
                    title =  {'แก้ไขข้อมูลผู้ใช้สำเร็จ'}
                    detail = {'ท่านได้แก้ไขข้อมูลผู้ใช้เรียบร้อยแล้ว'}
                    closeModal={() => handleModleSuccess()} 
                    goPage = {'/auth/home'}
                />
            ) : ''
        }
        <Box sx={boxProfile}>
            <Box sx={{margin:'1.5rem 0',textAlign:'center'}}>
                <img src={profileUser} alt=''/>
                {/* <img 
                    src={localStorage.getItem('image_url')} alt=''
                    style={{width:'150px',height:'150px',borderRadius:'100%'}}
                /> */}
                <Typography component="p" variant="p" color={'#743D90'} sx={{fontSize:'18px',fontWeight:'bold',margin:'1.5rem 0'}}>{profile['fullname']}</Typography>
                <Typography component="span" variant="span" sx={buttonActive}>Active</Typography>
            </Box>
            <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center'}}>
                <Typography component="p" variant="p" color={'#743D90'} sx={{fontSize:'18px',fontWeight:'bold',margin:'1.5rem 0'}}>รายละเอียด</Typography>
                {
                    isEditProfile ? '' : (
                        <Button
                            onClick={() => handleProfileEdit()}
                            fullWidth
                            variant="contained"
                            sx={buttomPrimary}
                            >
                            {'แก้ไข'}
                        </Button>
                    )
                }
                
            </Box>
            <hr style={{margin:'1.5rem 0',width:'100%'}}/>
            {
                isEditProfile ? 
                (
                    <EditProfile
                        titleName={titleName}
                        hospital={hospital}
                        department={department}
                        EditProfile={profile}
                        onChange={handleOnChange}
                        closeForm={() => handleProfileEdit()}
                        submitForm={() => handleSubmitForm()}
                    />
                ) : 
                (
                    <Grid container spacing={3}>
                        {
                        formEditUser.map((item,index) => (
                                <React.Fragment key={index}>
                                    <Grid item xs={12} sm={6} md={6} xl={5}>
                                        <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>{item['label']}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} xl={7}>
                                            
                                            <Typography component="p" variant="p" color={'#747676'} >
                                                {
                                                    profile[item['name']]
                                                }
                                            </Typography>
                                    </Grid>
                                </React.Fragment>
                            ))
                        }
                    </Grid>
                )
            
            }
            

        </Box>
    </Box>
  )
}

export default Profile

const EditProfile = (props) => {
    const {titleName,department,hospital,onChange,EditProfile,closeForm,submitForm} = props
    return (
        <>
            <Grid container spacing={3}>
                <Grid item xs={12} md={4} sm={12} lg={4} xl={3}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>คำนำหน้าชื่อ</Typography>
                    <FormControl fullWidth>
                            <Select
                                name='title_name'
                                value={EditProfile['title_name'] || ' '}
                                onChange={onChange}
                                fullWidth
                                >
                                <MenuItem  value=" ">เลือก</MenuItem>
                                {
                                    titleName.map((item,index) => (
                                        <MenuItem  key={`T${item['TitleNameID']}`} value={item['TitleName']}>{item['TitleName']}</MenuItem>
                                    ))
                                }
                            </Select>
                    </FormControl>
                </Grid>
                <Grid item  xs={12} md={4} sm={6} xl={4.5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>ชื่อ</Typography>
                    <TextField fullWidth  placeholder="ชื่อ" variant="outlined" 
                        type='text'
                        name='fname' 
                        value={EditProfile['fname']} 
                        onChange={onChange}/>
                </Grid>
                <Grid item  xs={12} md={4} sm={6} xl={4.5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{marginBottom:'0.5rem',fontWeight:'bold'}}>นามสกุล</Typography>
                    <TextField fullWidth  placeholder="นามสกุล" variant="outlined" 
                        type='text'
                        name='lname' 
                        value={EditProfile['lname']} 
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>อีเมล</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth  placeholder="อีเมล" variant="outlined" 
                        disabled
                        type='text'
                        name='email' 
                        value={EditProfile['email']} 
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>เลขบัตรประจำตัวประชาชน</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth  placeholder="เลขบัตรประจำตัวประชาชน" variant="outlined" 
                        disabled
                        type='text'
                        name='cid' 
                        value={EditProfile['cid']} 
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>เบอร์โทรศัพท์</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth  placeholder="เบอร์โทรศัพท์" variant="outlined" 
                        type='text'
                        name='phone' 
                        value={EditProfile['phone']} 
                        onChange={onChange} />
                </Grid>
                {/* <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>แผนก</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <FormControl fullWidth>
                            <Select
                            name='department_code'
                            value={EditProfile['department_code']}         
                            onChange={onChange}                   
                            fullWidth
                        >
                            <MenuItem  value=" ">เลือกแผนก</MenuItem>
                        {
                            department.map((item) => (
                                <MenuItem  key={item['DepartmentTypeCode']} value={item['DepartmentTypeCode']}>{item['DepartmentTypeName']}</MenuItem>
                            ))
                        }   
                        </Select> 
                    </FormControl>
                </Grid> */}
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>โรงพยาบาล/สถานพยาบาล</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth  placeholder="โรงพยาบาล/สถานพยาบาล" variant="outlined" 
                        disabled
                        type='text'
                        name='hospital_name' 
                        value={EditProfile['hospital_name']} 
                        onChange={onChange} />
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={5}>
                    <Typography component="p" variant="p" color={'#747676'} sx={{fontWeight:'bold'}}>สิทธิการใช้งาน</Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={6} xl={7}>
                    <TextField fullWidth  placeholder="สิทธิการใช้งาน" variant="outlined" 
                        disabled
                        type='text'
                        name='group_name' 
                        value={EditProfile['group_name']} 
                        onChange={onChange} />
                </Grid>
            </Grid>
            <hr style={{margin:'1.5rem 0',width:'100%'}}/>
            <Box sx={{display:'flex',justifyContent:'center',gridGap:'1rem',marginTop:'1.5rem'}}>
                <Button 
                    onClick={closeForm}
                    fullWidth
                    variant="contained"
                    sx={buttomSecondary}
                    >
                    {'ยกเลิก'}
                </Button>
                <Button 
                    onClick={submitForm}
                    fullWidth
                    variant="contained"
                    sx={buttomPrimary}
                    >
                    {'บันทึก'}
                </Button>            
            </Box>
        </>
    )
}