import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Pagination, Select } from "@mui/material";
import LoaderAnimation from '../../components/LoaderAnimation'
import * as api from '../../services/ApiConsultDoctor'
import moment from "moment";
import CircleIcon from '@mui/icons-material/Circle';
import { notifyError, notifyInfo } from "../../components/NotifyToast";
import { useLocation } from "react-router-dom";


export function SystemPatientInformationData({pid}) {

    const [loading, setLoading] = useState(false)
    const location = useLocation()

    const [hospital, setHospital] = useState('')
    const [optionHospital, setOptionHospital] = useState([])

    const [date, setDate] = useState('')
    const [optionDate, setOptionDate] = useState([])

    const [dataInformation, setDataInformation] = useState([])
    const [dataPersonal, setDataPersonal] = useState([])
    const [notData, setNotData] = useState('')

    const [data, setData] = useState([])

    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [pidModal, setPidModal] = useState(new URLSearchParams(location.search).get('PID'))
    const [newPid,setNewPid] = useState(!pid ? pidModal :  pid)

    useEffect(() => {
        findHospital()
        // console.log("=====> ",pid)
    }, [])

    useEffect(() => {
        setDataPersonal([])
        setDataInformation([])
        setNotData('')
        setDate('')
        setOptionDate([])

        findInformationPersonal()
        findInformation()
    }, [hospital])

    useEffect(() => {
        if (date === '' || date === ' ') {
            findInformation()
        } else {
            filterDataInformation()
        }
    }, [date])

    const findInformation = async () => {
        console.log(hospital)
        setLoading(true)
        let res = await api.findInformation(newPid, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let dataAll = res['data']['data']
                dataAll.sort(function (a, b) {
                    return new Date(b.medicalDate) - new Date(a.medicalDate)
                })
                setDataInformation(res['data']['data'])
                setData(res['data']['data'])

                const List = []
                for (let i = 0; i < res['data']['data'].length; i++) {
                    if (res['data']['data'][i]) {
                        List.push(res['data']['data'][i]['medicalDate'])
                    }
                }
                setOptionDate(List)

            } else {
                setNotData(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findInformationPersonal = async () => {
        setLoading(true)
        let res = await api.findInformationPersonal(newPid, hospital === ' ' ? '' : hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataPersonal(res['data']['data']['personal_information_list'])
                setMaxPage((Math.ceil(res['data']['data']['personal_information_list'].length / 1)))
            } else {
                setNotData(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findHospital = async () => {
        setLoading(true)
        let res = await api.findInformationPersonal(newPid, hospital)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let option = []
                for (let i = 0; i < res['data']['data']['personal_information_list'].length; i++) {
                    if (res['data']['data']['personal_information_list'][i]) {
                        option.push({
                            key: res['data']['data']['personal_information_list'][i]['hos_id'],
                            value: res['data']['data']['personal_information_list'][i]['hos_name']
                        })
                    }
                }
                const unique = [...new Map(option.map((m) => [m.key, m])).values()] //กรองชื่อซ้ำ
                setOptionHospital(unique)
                console.log(unique)
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const filterDataInformation = () => {
        const List = []
        for (let i = 0; i < data.length; i++) {
            if (data[i]['medicalDate'] === date) {
                List.push(data[i])
            }
        }
        setDataInformation(List)
    }

    useEffect(() => {
        let start = page * 1 - 1
        setShowList(dataPersonal.slice(start, start + 1))
    }, [page, dataPersonal])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                display: 'grid',
                gridGap: 20,
                fontSize: 18,
                margin: {xl:'2rem',lg:'2rem',md:'2rem',sm:'2rem',xs:'2rem 1rem'},
                // marginTop: 5,
                // marginLeft: 5,
                // marginRight: 5,
                '@media (max-width: 1023px)': {
                    gridGap: '3%',
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    gridGap: '2%',
                    fontSize: 14,
                }
            }}>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    border: 1,
                    borderRadius: 2,
                    borderColor: '#E1E3E9',
                    padding: '20px',
                    '@media (max-width: 1023px)': {
                        gridTemplateColumns: '1fr',
                        padding: '15px'
                    },
                    '@media (max-width: 800px)': {
                        justifyItems: 'center',
                        padding: '10px'
                    },
                    bgcolor: '#FFF',
                }}>
                    <FormControl sx={{ width: '90%' }} size="small">
                        <div>โรงพยาบาลที่เข้ารับบริการ</div>
                        <Select
                            value={hospital || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setHospital(value)
                                console.log(value)
                            }}
                        >
                            <MenuItem value=" ">ทั้งหมด</MenuItem>
                            {optionHospital.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item['key']}
                                >
                                    {item['value']}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ width: '90%' }} size="small">
                        <div>ค้นหาวันที่</div>
                        <Select
                            value={date || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setDate(value)
                            }}
                        >
                            <MenuItem value=" ">วันที่ทั้งหมด</MenuItem>
                            {optionDate.map((item, index) => (
                                <MenuItem
                                    key={index}
                                    value={item}
                                >
                                    {convertDate(item)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                {!notData ?
                    <>
                        {showList.map((i, idx) => (
                            <Box key={idx} sx={{ display: 'grid', gridGap: '4%' }}>
                                <Box sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: '#E1E3E9',
                                    padding: '20px',
                                    '@media (max-width: 1023px)': {
                                        padding: '15px'
                                    },
                                    '@media (max-width: 800px)': {
                                        padding: '10px'
                                    },
                                    bgcolor: '#FFF',
                                }}>
                                    <div style={{ fontWeight: '700', color: '#743D90' }}>ข้อมูลส่วนตัว</div>
                                    <div></div>

                                    <Box sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                        gridGap: '20px',
                                        '@media (max-width: 1023px)': {
                                            gridTemplateColumns: '1fr 1fr',
                                            gridGap: '15px'
                                        },
                                        '@media (max-width: 800px)': {
                                            gridTemplateColumns: '1fr',
                                            gridGap: '10px',
                                        }
                                    }} key={idx}>
                                        <div>
                                            <div style={{ color: '#743D90' }}>ชื่อ-นามสกุล</div>
                                            <div style={{ color: '#747676' }}>{i['pname'] + i['fname'] + ' ' + i['lname']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>ที่อยู่</div>
                                            <div style={{ color: '#747676' }}>{i['address']['house_no'] + ' ' + 'หมู่ ' + i['address']['village_no'] + ' ' + i['address']['full_address']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>เลขประจำตัวประชาชน</div>
                                            <div style={{ color: '#747676' }}>{i['pid']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>โทรศัพท์</div>
                                            <div style={{ color: '#747676' }}>{i['home_phone']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>วันเกิด</div>
                                            <div style={{ color: '#747676' }}>{convertDate(moment(i['birthday']).format('DD MMM YYYY'))}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>โทรศัพท์มือถือ</div>
                                            <div style={{ color: '#747676' }}>{i['phone_number']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>อายุ</div>
                                            <div style={{ color: '#747676' }}>{i['age'] + ' ปี'}</div>
                                        </div>
                                    </Box>
                                </Box>
                                <Box sx={{
                                    border: 1,
                                    borderRadius: 2,
                                    borderColor: '#E1E3E9',
                                    padding: '20px',
                                    '@media (max-width: 1023px)': {
                                        padding: '15px'
                                    },
                                    '@media (max-width: 800px)': {
                                        padding: '10px'
                                    },
                                    bgcolor: '#FFF',
                                }}>
                                    <div style={{ fontWeight: '700', color: '#743D90' }}>ข้อมูลทางการแพทย์</div>
                                    <div></div>

                                    <Box sx={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                        gridGap: '20px',
                                        '@media (max-width: 1023px)': {
                                            gridTemplateColumns: '1fr 1fr',
                                            gridGap: '15px'
                                        },
                                        '@media (max-width: 800px)': {
                                            gridTemplateColumns: '1fr',
                                            gridGap: '10px',
                                        }
                                    }}>
                                        <div>
                                            <div style={{ color: '#743D90' }}>หมายเลขผู้ป่วยนอก</div>
                                            <div style={{ color: '#747676' }}>{i['hn']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>กรุ๊ปเลือด</div>
                                            <div style={{ color: '#747676' }}>{i['blood_group']}</div>
                                        </div>
                                        <div>
                                            <div style={{ color: '#743D90' }}>แพ้ยา</div>
                                            <div style={{ color: '#747676' }}>{i['drug_allergy']}</div>
                                        </div>
                                    </Box>
                                </Box>
                            </Box>
                        ))}

                        <Box sx={{ margin: 'auto' }}>
                            <Pagination
                                count={maxPage}
                                page={page}
                                onChange={handlePageChange}
                                shape="rounded"
                                color="primary"
                            />
                        </Box>

                        <Box sx={{
                            display: 'flex',
                            gridGap: 15,
                            overflow: "hidden",
                            overflowX: "scroll",
                            marginBottom:'1rem'
                        }}>
                            {dataInformation.map((i, idx) => (
                                <Box sx={{
                                    minWidth: '400px',
                                    backgroundColor: '#F3EDF7',
                                    borderRadius: 3,
                                    padding: 2,
                                    maxWidth: '300px',
                                    overflowY:'auto',
                                    minHeight:'100%',
                                    maxHeight:'700px',
                                    marginBottom:'1rem'
                                }} key={idx}>
                                    <div style={{ fontWeight: '700', marginBottom: 5 }}>{convertDate(i['medicalDate'])}</div>
                                    <Box sx={{
                                        display: 'grid',
                                        gridGap: 15,
                                        '@media (max-width: 1023px)': {
                                            padding: 2,
                                            gridGap: 15,
                                        },
                                        '@media (max-width: 800px)': {
                                            padding: 1,
                                            gridGap: 8,
                                        }
                                    }}>
                                        <Box sx={{
                                            backgroundColor: '#FFFFFF',
                                            borderRadius: 2,
                                            padding: 2
                                        }}>
                                            <div style={{ color: '#272727' }}>ประวัติการแพทย์</div>
                                            <div style={{ color: '#743D90' }}>{i['medicalInformation']['hos_name']}</div>
                                            {i['medicalInformation']['visit'] ?
                                                null
                                                :
                                                <>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>CC</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['cc'] === '' ? '-' : i['medicalInformation']['cc']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Ht</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['ht'] === '' ? '-' : i['medicalInformation']['ht']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Pi</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['pi'] === '' ? '-' : i['medicalInformation']['pi']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Bt</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['bt'] === '' ? '-' : i['medicalInformation']['bt']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Pe</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['pe'] === '' ? '-' : i['medicalInformation']['pe']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Rr</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['rr'] === '' ? '-' : i['medicalInformation']['rr']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Bp</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['bp'] === '' ? '-' : i['medicalInformation']['bp']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Bmi</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['bmi'] === '' ? '-' : i['medicalInformation']['bmi']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Pr</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['pr'] === '' ? '-' : i['medicalInformation']['pr']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>sat O2</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['sat_o2'] === '' ? '-' : i['medicalInformation']['sat_o2']}</div>
                                                    <div style={{ color: '#743D90', margin: '0 5%' }}>Bw</div>
                                                    <div style={{ color: '#707070', margin: '0 10%' }}>{i['medicalInformation']['bw'] === '' ? '-' : i['medicalInformation']['bw']}</div>
                                                </>
                                            }

                                        </Box>

                                        {i['medicalInformation']['lab_list'] ?
                                            <Box sx={{
                                                backgroundColor: '#FFFFFF',
                                                borderRadius: 2,
                                                padding: 2
                                            }}>
                                                <div style={{ color: '#272727' }}>ผลแลป</div>
                                                {i['medicalInformation']['lab_list'].map((item, index) => (
                                                    <div key={index} style={{ color: '#707070', margin: '0 10%', display: 'flex', justifyContent: 'center', gridGap: 5 }}> <CircleIcon style={{ width: '5px' }} /> {item['detail']}</div>
                                                ))}
                                            </Box>
                                            : null
                                        }

                                        {i['medicalInformation']['diagnosis'] ?
                                            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, padding: 2 }}>
                                                <div style={{ color: '#272727' }}>การวินิจฉัย</div>
                                                {i['medicalInformation']['diagnosis'].map((item, index) => (
                                                    <div key={index} style={{ color: '#707070', margin: '0 10%', display: 'flex', justifyContent: 'center', gridGap: 5 }}> <CircleIcon style={{ width: '5px' }} /> {item['diag']}</div>
                                                ))}
                                            </Box>
                                            : null
                                        }

                                        {i['medicalInformation']['order_list']?.['0'] ?
                                            <Box sx={{ backgroundColor: '#FFFFFF', borderRadius: 2, padding: 2 }}>
                                                <div style={{ color: '#272727' }}>การจ่ายยา</div>
                                                {i['medicalInformation']['order_list'].map((item, index) => (
                                                    <div key={index} style={{ color: '#707070', margin: '0 10%', display: 'flex', justifyContent: 'center', gridGap: 5 }}> <CircleIcon style={{ width: '5px' }} /> {item['med']}</div>
                                                ))}
                                            </Box>
                                            : null
                                        }
                                    </Box>

                                </Box>
                            ))}
                        </Box>

                    </>
                    : null
                }

                {notData ?
                    <div style={{ display: 'flex', justifyContent: 'center', margin: '35%', fontSize: 26 }}>ไม่พบข้อมูล</div>
                    : null
                }
            </Box>
        </>

    )
}