import React, { useEffect,useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { logout } from '../store/authSlice'
import { io } from 'socket.io-client';
import { environment } from '../config.env'
import ModalSOS from '../components/ModalController/ModalSOS';

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const PrivateRoute = ({ children }) => {
    // redux
    const authen = useSelector((state) => state.auth);
    const accessToken = localStorage.getItem('AccessToken')
    let location = useLocation();
    const dispatch = useDispatch()
    const [socket, setSocket] = useState(null)
    const [openModalSOS, setOpenModalSOS] = useState(false)
    const [payloadAlertSOS, setPayLoadAlertSOS] = useState(null)
    const userData = useSelector((state) => state.auth.user_data)

    useEffect(() => {
        // console.log(userData)

        const socket = io(ENDPOINT_DOMAIN + '/', {
            path: "/realtime", //รอ confirm จาก backend
            autoConnect: true,
            reconnection: true,
            transports: ["websocket"]
          })
          setSocket(socket)
      
          socket.on("connect_error", () => {
            console.log('on : connect_error');
            // socket.connect();
          })
      
          socket.io.on("reconnect", () => {
            console.log('on : reconnect');
          })
      
          socket.io.on("connect", () => {
            console.log('on : connect');
          })
      
          return () => {
            console.log('disconnet');
            socket.disconnect();
          }
    },[])

    useEffect(() => {
        
        if(authen.isAuthenticated && accessToken){
            // console.log(authen)
            let now = moment().unix()
            let expire = authen.user.ExpireInTimestamp

            if(expire){
                let time = expire - now
                // console.log("---> ",time)
                if(time <= 0){
                    console.log('token หมดอายุ')
                    localStorage.clear()
                    dispatch(logout())
                    return () => {
                        console.log('disconnet');
                        socket.disconnect();
                      }
                }else{
                    // console.log('token ยังไม่หมดอายุ')
                    // console.log(userData)
                    let UID = userData

                    if (socket) {

                    socket.emit("join-sos-rama", { payload: { uid: UID, platform: 'rama-dashboard' } });

                    // socket.on("join-sos-rama-response", ({ payload }) => {
                    // console.log('on : join-sos-rama-response');
                    // console.log('join-sos-rama-response payload : ', payload)
                    // });

                    socket.on('sos-notification', ({ payload }) => {
                        console.log('on : sos-notification : ', payload);
                        //content modal
                
                        onPlaySound()
                        setOpenModalSOS(true)
                        setPayLoadAlertSOS(payload)
                    })

                    }
                }
            }else{
                localStorage.clear()
                dispatch(logout())

                disconnectSocket()
            }
        }else{
            console.log('ไม่มี auth')
            // console.log(authen)
            disconnectSocket()
            
        }

    },[location])
    
    console.log('PrivateRoute =>', authen.isAuthenticated)

    const onPlaySound = function () {
        const soundPath = require(`../assets/audioSOS/electronic-school-bell-81033.mp3`);
        PlayAudioSound(soundPath);
    }
    
    const PlayAudioSound = function (path) {
        const audioElement = new Audio(path);
        try {
            audioElement.play();
        } catch (error) {
            console.log(error)
        }

    }

    const disconnectSocket = () => {
        return () => {
            console.log('disconnet');
            socket.disconnect();
        }
    }

    return (authen.isAuthenticated && accessToken ? 
        <>
            {children} 
            {openModalSOS && <ModalSOS onClose={() => { setOpenModalSOS(false) }} payload={payloadAlertSOS} />}
        </>
        : <Navigate to='/' state={{ from: location }} />);
};

export default PrivateRoute;