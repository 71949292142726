import { Box, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AccessPermission from './AccessPermission'
import { buttonPrimary,buttonSecondary,contentMenu } from './mainCss'
import UserPermission from './UserPermission'
import HospitalPermission from './HospitalPermission'
import SavePermission from './SavePermission'
import ManageMedication from './ManageMedication'
import { useLocation, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../store/menuSlice';

function ManageUser() {
    const navigate = useNavigate()
    const [typeManage,setTypeManage] = useState('access')
    const routeState = useLocation()
    const dispatch = useDispatch()

    if(!routeState.state){
      routeState.state = {typeManage : 'access'}
    }

    const menuManage = [
        {
            id: 'menu01',
            menu: 'สิทธิ์การเข้าใช้งาน',
            type: 'access'
          },
          {
            id: 'menu02',
            menu: 'อนุญาตผู้ใช้งาน',
            type: 'user'
          },
          {
            id: 'menu03',
            menu: 'การจัดการโรงพยาบาล',
            type: 'hospital'
          },
          {
            id: 'menu04',
            menu: 'บันทึกการใช้งาน',
            type: 'save'
          },
          {
            id: 'menu05',
            menu: 'จัดการรายการยา',
            type: 'medication'
          }
        ]
    
    useEffect(() => {
      dispatch(menuActive('การจัดการผู้ใช้งาน'))
    },[])
  
    const handleManage = (type_manage) => {
      // console.log("type manage ====>",type_manage)
      setTypeManage(type_manage)
      if(type_manage === 'hospital'){
         return navigate("/auth/manage_user", { state: { typeManage: type_manage , Tap : 'department' }})
      }
      navigate("/auth/manage_user", { state: { typeManage: type_manage , Tap : '' }})
    }

    // console.log("routeState ====> ",routeState.state['typeManage'])
    // console.log("routeState ====> ",routeState)
    return (
    <div>
        <Box sx={contentMenu}>
            {
                menuManage.map((menu) => (
                    <Button key={menu['id']} 
                            sx={routeState.state['typeManage'] === menu['type'] ? buttonPrimary : buttonSecondary} 
                            onClick={() => handleManage(menu['type'])}>
                            {menu['menu']}
                    </Button>
                ))
            }
        </Box>

        {
         routeState.state['typeManage'] === 'access'? (<AccessPermission/>) : '' 
        }
        {
         routeState.state['typeManage'] === 'user'? (<UserPermission/>) : '' 
        }
        {
         routeState.state['typeManage'] === 'hospital'? (<HospitalPermission/>) : '' 
        }
        {
         routeState.state['typeManage'] === 'save'? (<SavePermission/>) : '' 
        }
        {
         routeState.state['typeManage'] === 'medication' ? (<ManageMedication/>) : ''
        }
        
    </div>
  )
}

export default ManageUser