import axios from "axios";
import { environment } from '../../config.env'

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain

const domain = ENDPOINT_DOMAIN

export function findAppointment(date,channel,appointment_state) {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/microservice/appointment/findAppointment?AppointDate=${date}&order=DocNumber&sort=desc&DocStatus=W&AppointState=${appointment_state}&Channel=${channel}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'rama-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findAppointmentNewAppoint() {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/microservice/appointment/findAppointment?order=DocNumber&sort=desc&DocStatus=W&AppointState=NEW_APPOINT`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'rama-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }

        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function cancelAppointment(DocNumber) {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/microservice/appointment/cancelAppointment?DocNumber=${DocNumber}`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'rama-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.delete(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function findAppointmentState() {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/microservice/appointment/findState?DocStatus=W&order=ID&sort=asc`
        let header = {
            'Content-Type': 'application/json',
            'Platform': 'rama-dashboard',
            'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        }
        axios.get(url, { headers: header }).then((res) => {
            resolve(res)
        }).catch((err) => {
            resolve(false)
        })
    })
}

export function changeState(appointment_state,id) {
    return new Promise((resolve) => {
        let url = `${domain}/dashboard-api/api/v1/microservice/appointment/changeState?ID=${id}`
        // let body = {
        //     AppointState: appointment_state
        // }
        // let header = {
        //     'Content-Type': 'application/json',
        //     'Platform': 'rama-dashboard',
        //     'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`
        // }
        // axios.put(url, { headers: header }).then((res) => {
        //     resolve(res)
        // }).catch((err) => {
        //     resolve(false)
        // })
        let data = JSON.stringify({
            "AppointState": appointment_state
          });
          
          let config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: url,
            headers: { 
              'Authorization': `Bearer ${localStorage.getItem('AccessToken')}`, 
              'Platform': 'rama-dashboard', 
              'Content-Type': 'application/json'
            },
            data : data
          };
          
          axios.request(config)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            resolve(response)
          })
          .catch((error) => {
            // console.log(error);
            resolve(false)
          });
    })    
}