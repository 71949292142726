import React, { useState, useEffect, useMemo } from "react";
import { Box, FormControl, Select, MenuItem, Button, Tab, Tabs, Table, TableBody, TableCell, TableHead, TableContainer, TableRow, TextField, Stack, Pagination, Typography } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import ModalSetupRoom from "../../../components/ModalController/ModalSetupRoom";
import * as api from '../../../services/ApiConsultDoctor'
import LoaderAnimation from '../../../components/LoaderAnimation'
import styles from './QueueSystem.module.css'
import { notifyError, notifyInfo, notifySuccess } from "../../../components/NotifyToast";
import { io } from 'socket.io-client';

import { environment } from '../../../config.env'
import { useDispatch } from "react-redux";
import { logout } from './../../../store/authSlice';

const ENDPOINT_DOMAIN = environment.production ? environment.production_domain : environment.development_domain


function AskOrganizingRoom() {

    const dispatch = useDispatch()

    const [socket, setSocket] = useState(null)
    const [topic, setTopic] = useState(null)
    const [loading, setLoading] = useState(false)

    const [roomList, setRoomList] = useState([])
    const [department, setDepartment] = useState('')
    const [optionDepartment, setOptionDepartment] = useState([])
    const [location, setLocation] = useState('')
    const [optionLocation, setOptionLocation] = useState([])
    const [time, setTime] = useState('')
    const [optionTime, setOptionTime] = useState([
        {
            key: 'morning',
            value: 'เช้า'
        },
        {
            key: 'afternoon',
            value: 'บ่าย'
        },
        {
            key: 'all',
            value: 'ทั้งหมด'
        }
    ])

    const [room, setRoom] = useState('')
    const [optionRoom, setOptionRoom] = useState([])

    const [openModal, setOpenModal] = useState(false)
    const [openModalChange, setOpenModalChange] = useState(false)
    const [search, setSearch] = useState('')

    const [appointmentAssign, setAppointmentAssign] = useState([])
    const [appointmentList, setAppointmentList] = useState([])


    const [value, setValue] = useState(0);

    const [numPage, setNumPage] = useState(5)
    const [page, setPage] = useState(1)
    const [maxPage, setMaxPage] = useState(1)
    const [showList, setShowList] = useState([])

    const [numPage2, setNumPage2] = useState(5)
    const [page2, setPage2] = useState(1)
    const [maxPage2, setMaxPage2] = useState(1)
    const [showList2, setShowList2] = useState([])

    const [dataAdd, setDataAdd] = useState([])

    const [dataChange, setDataChange] = useState([])

    useEffect(() => {
        const socket = io(ENDPOINT_DOMAIN + '/', {
            path: "/socket/socket.io",
            autoConnect: true,
            reconnection: false,
            transports: ["websocket"]
        })

        console.log(socket)

        setSocket(socket)

        socket.on("connect_error", () => {
            console.log('on : connect_error');
            socket.connect();
        });

        socket.io.on("reconnect_attempt", () => {
            console.log('on : reconnect_attempt');
        });

        socket.io.on("reconnect", () => {
            console.log('on : reconnect');
        });

        socket.on("subscribed", (data) => {
            console.log('on : subscribed', data);

        });

        socket.on("unsubscribed", () => {
            console.log('on : unsubscribed');
        });

        // socket.on('connect', () => {
        //     setLoading(false)
        //     console.log('Socket connected');
        // });

        return () => {
            socket.disconnect();
        };
    }, [])

    useEffect(() => {
        if (topic) {
            socket.emit("unsubscribe", topic);

            socket.emit("subscribe", topic);


            socket.on("update_queue", (data) => {
                console.log('case 1')
                console.log('on : update_queue ', data);
                if (data['appointment_assign_room_list']?.length > 0) {
                    console.log('case 2')
                    setAppointmentAssign(data['appointment_assign_room_list'])
                    setMaxPage2((Math.ceil(data['appointment_assign_room_list'].length / numPage2)))
                    console.log('case 3')
                } else {
                    setAppointmentAssign([])
                    setMaxPage2(1)
                }

                if (data['appointment_list']?.length > 0) {
                    console.log('case 4')
                    setAppointmentList(data['appointment_list'])
                    setMaxPage((Math.ceil(data['appointment_list'].length / numPage)))
                    console.log('case 5')
                } else {
                    setAppointmentList([])
                    setMaxPage(1)
                }
            })
        }

    }, [topic])

    useEffect(() => {
        console.log(topic)
    }, [topic])

    useEffect(() => {
        console.log(search)
    }, [search])

    useEffect(() => {
        getClinicAndRoom()
    }, [])

    useEffect(() => {
        getLocation()
        getRoom()
    }, [department])

    // useEffect(() => {
    //     getClinicCheckInAppointment()
    // }, [time])

    useEffect(() => {
        if (department && location && time) {
            getClinicCheckInAppointment()
        }
    }, [numPage, numPage2, time])

    useEffect(() => {
        setPage(1)
        setPage2(1)
    }, [numPage, numPage2])

    useEffect(() => {
        let start = page * numPage - numPage
        setShowList(appointmentList.slice(start, start + numPage))
    }, [page, appointmentList])

    useEffect(() => {
        let start = page2 * numPage2 - numPage2
        setShowList2(appointmentAssign.slice(start, start + numPage2))
    }, [page2, appointmentAssign])




    const handleChangeTab = (event, newValue) => {
        setSearch('') ///clear state
        setValue(newValue)
    }

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handlePageChange2 = (event, page) => {
        setPage2(page)
    }

    const getClinicAndRoom = async () => {
        setLoading(true)
        let res = await api.getClinicAndRoom()
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setRoomList(res['data']['room_list'])
                setOptionDepartment(res['data']['clinic_list'])
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const getClinicCheckInAppointment = async () => {
        setLoading(true)
        let res = await api.getClinicCheckInAppointment(department, location, time)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setAppointmentAssign(res['data']['appointment_assign_room_list'])
                setMaxPage2((Math.ceil(res['data']['appointment_assign_room_list'].length / numPage2)))

                setAppointmentList(res['data']['appointment_list'])
                setMaxPage((Math.ceil(res['data']['appointment_list'].length / numPage)))

                setTopic(localStorage.getItem('hospital_code') + '/' + department + '/' + location + '/' + time)

            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const callHistoryInquiry = async (id) => {
        setLoading(true)
        let res = await api.callHistoryInquiry(id)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('ซักประวัติแล้ว')
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const openVN = async (id) => {
        setLoading(true)
        let res = await api.openVN(id)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                notifySuccess('เปิด visit แล้ว')
            } else {
                if (res['data']['code'] === 401) {
                    localStorage.clear()
                    dispatch(logout())
                } else {
                    notifyInfo(res['data']['message'])
                }
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const locationList = []
    const getLocation = () => {
        for (let i = 0; i < roomList.length; i++) {
            if (roomList[i]['clinic_code'] === department) {
                const location = roomList[i]['location'];

                if (!locationList.includes(location)) {
                    locationList.push(location);
                }
            }
        }
        console.log(locationList)
        setOptionLocation(locationList.map(i => {
            return {
                key: i,
                value: i
            }
        }))
    }

    const roomAll = []
    const getRoom = () => {
        for (let i = 0; i < roomList.length; i++) {
            if (roomList[i]['clinic_code'] == department) {
                roomAll.push(roomList[i])
            }
        }
        setOptionRoom(roomAll.map(i => {
            return {
                key: i['room_name'],
                conference_link: i['conference_link']
            }
        }))
    }

    const clickConference = () => {
        console.log(room)
        console.log(optionRoom)
        const url = []
        for (let i = 0; i < optionRoom.length; i++) {
            if (optionRoom[i]['key'] == room) {
                url.push(optionRoom[i]['conference_link'])
            }
        }
        window.open(url, '_system')
    }

    const callHistory = (item, id) => {
        if (item === false) {
            return (
                <>
                    <Button sx={{
                        backgroundColor: '#FCF5BC',
                        width: '100%'
                    }}
                        onClick={() => { callHistoryInquiry(id) }}
                    >ซักประวัติ</Button>
                </>
            )
        } else if (item === true) {
            return (
                <>
                    <div className={styles.history} >
                        <div><CheckIcon /></div>
                        <div>ซักประวัติแล้ว</div>
                    </div>
                </>
            )
        }
    }

    const setupRoom = (item, id) => {
        if (item === true) {
            return (
                <>
                    <Button sx={{ width: '100%' }} variant="contained" onClick={() => { clickSetupRoom(id) }}>จัดห้อง</Button>
                </>
            )
        } else if (item === false) {
            return (
                <>
                    <Button sx={{
                        backgroundColor: '#FCF5BC',
                        width: '100%'
                    }}
                        onClick={() => { openVN(id) }}
                    >เปิด VN</Button>
                </>
            )
        }
    }


    const clickSetupRoom = (id) => {
        setDataAdd(id)
        setOpenModal(true)
    }


    const clickChangeRoom = (id) => {
        setDataChange(id)
        setOpenModalChange(true)
    }

    const groupCodeLine = (clinic_code, queue_code) => {
        let queue_str = String(queue_code);

        if (queue_str.length < 2) {
            queue_str = '000' + queue_str;
        } else if (queue_str.length < 3) {
            queue_str = '00' + queue_str;
        } else if (queue_str.length < 4) {
            queue_str = '0' + queue_str;
        }

        return clinic_code + queue_str;
    }


    return (
        <>
            <LoaderAnimation loading={loading} />
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                color: '#743D90',
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1,
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14
                }
            }}>
                <div style={{ fontWeight: 'bold' }}>Filters</div>
                <Box sx={{
                    padding: '10px 40px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                    gridGap: 30,
                    alignItems: 'flex-end',
                    fontSize: 18,

                    '@media (max-width: 1023px)': {
                        padding: '5px 30px',
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: 20,
                        fontSize: 16,
                    },
                    '@media (max-width: 800px)': {
                        padding: '5px 20px',
                        gridTemplateColumns: '1fr',
                        gridGap: 10,
                        fontSize: 14,
                    }
                }}>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold'
                        }}>เลือกรหัสแผนก</Typography>
                        <Select
                            size="small"
                            value={department || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setDepartment(value)
                                setLocation('')
                                setOptionLocation([])
                                setTime('')
                            }}
                        >
                            <MenuItem value=" ">เลือกรหัสแผนก</MenuItem>
                            {optionDepartment.map(i => (
                                <MenuItem
                                    key={i['clinic_code']}
                                    value={i['clinic_code']}
                                >{i['clinic_code'] + ' (' + i['department_name'] + ')'}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold'
                        }}>
                            เลือกสถานที่
                        </Typography>
                        <Select
                            size="small"
                            disabled={!department || department === ' ' ? true : null}
                            value={location || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setLocation(value)
                                setTime('')
                            }}
                        >
                            <MenuItem value=" ">เลือกสถานที่</MenuItem>
                            {optionLocation.map(i => (
                                <MenuItem
                                    key={i['key']}
                                    value={i['key']}
                                >{i['value']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold'
                        }}>
                            เลือกช่วงเวลา
                        </Typography>
                        <Select
                            size="small"
                            disabled={!location || location === ' ' ? true : null}
                            value={time || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setTime(value)
                            }}
                        >
                            <MenuItem value=" ">เลือกเวลา</MenuItem>
                            {optionTime.map(i => (
                                <MenuItem
                                    key={i['key']}
                                    value={i['key']}
                                >{i['value']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Typography sx={{
                            fontWeight: 'bold'
                        }}>
                            เลือกห้อง
                        </Typography>
                        <Select
                            size="small"
                            disabled={!department || department === ' ' ? true : null}
                            value={room || ' '}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setRoom(value)
                            }}
                        >
                            <MenuItem value=" ">เลือกห้อง</MenuItem>
                            {optionRoom.map(i => (
                                <MenuItem
                                    key={i['key']}
                                    value={i['key']}
                                >{i['key']}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <FormControl>
                        <Button
                            variant="contained"
                            disabled={room === '' ? true : null}
                            onClick={() => { clickConference() }}
                        >Conference</Button>
                    </FormControl>
                </Box>
            </Box >
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                color: '#743D90',
                '@media (max-width: 1023px)': {
                    marginTop: 2,
                    padding: 1,
                    fontSize: 16,
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14
                }
            }}>
                <Box sx={{
                    borderBottom: 1,
                    borderColor: '#ffffff',
                }}>
                    <Tabs variant="fullWidth" value={value} onChange={handleChangeTab} centered>
                        <Tab sx={{
                            width: '100%',
                            fontSize: 18,
                            fontWeight: 'bold',
                            '@media (max-width: 800px)': {
                                width: '50%',
                                fontSize: 14
                            }
                        }} label="คิวรอซักประวัติและจัดห้อง" />
                        <Tab sx={{
                            width: '100%',
                            fontSize: 18,
                            fontWeight: 'bold',
                            '@media (max-width: 800px)': {
                                width: '50%',
                                fontSize: 14
                            }
                        }} label="คิวจัดห้องแล้ว" />
                    </Tabs>

                    {value === 0 ?
                        <>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                gridGap: 20,
                                margin: 2,
                                '@media (max-width: 800px)': {
                                    justifyContent: 'center',
                                    gridGap: 10
                                }
                            }}>
                                <div>จำนวนทั้งหมด {appointmentList.length}</div>
                                <TextField
                                    size="small"
                                    value={search}
                                    onChange={(e) => {
                                        setSearch(e.target.value)
                                    }}
                                >
                                </TextField>
                            </Box>

                            <TableContainer>
                                <Table sx={{ marginTop: 3, minWidth: '1300px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>#</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เวลาซักประวัติ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>รหัสนำทาง</TableCell>
                                            {/* <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>รหัสคลินิก</TableCell> */}
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>ชื่อคลินิก</TableCell>
                                            {/* <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>แพทย์</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>HN</TableCell> */}
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>ชื่อ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เรียกซักประวัติ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>จัดห้อง</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {showList.filter((item) => {
                                        return (item?.['fname'] + item?.['lname']).includes(search)
                                    }).map((i, idx) => (
                                        <TableBody key={idx}>
                                            <TableRow>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['history_inquiry_start'] ? i['history_inquiry_start'] + ' น.' : i['appointment_time'] + ' น.'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['queue'] ? groupCodeLine(i['clinic_code'], i['queue']) : '-'}</TableCell>
                                                {/* <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['clinic_code']}</TableCell> */}
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['clinic_name']}</TableCell>
                                                {/* <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['doctor_name'] ? i['doctor_name'] : '-'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['hn'] ? i['hn'] : '-'}</TableCell> */}
                                                <TableCell style={{ color: '#747676' }}>{i['fname'] + ' ' + i['lname']}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{callHistory(i['is_call_history_inquiry'], i['_id'])}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{setupRoom(i['is_open_vn'], i['_id'])}</TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}

                                </Table>
                            </TableContainer>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: 2
                            }}>
                                <Box sx={{ width: '100px' }}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={numPage}
                                            onChange={(e) => {
                                                const {
                                                    target: { value },
                                                } = e;
                                                setNumPage(value)
                                            }}
                                            size='small'
                                            sx={{ width: '100px', fontSize: '14px' }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={maxPage}
                                        page={page}
                                        onChange={handlePageChange}
                                        shape="rounded"
                                        color="primary"
                                    />
                                </Stack>
                            </Box>

                            {openModal ?
                                <ModalSetupRoom
                                    onClose={(e) => {
                                        if (e === true) {
                                            // getClinicCheckInAppointment() // มี state อยู่แล้ว ปกติจะไม่เขียนแบบนี้
                                        }
                                        setOpenModal(false)
                                    }}
                                    data={dataAdd}
                                    title='จัดห้อง'
                                />
                                : null
                            }
                        </>
                        : null
                    }

                    {value === 1 ?
                        <>
                            <Box sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'end',
                                gridGap: 20,
                                margin: 2,
                                '@media (max-width: 800px)': {
                                    justifyContent: 'center',
                                    gridGap: 10
                                }
                            }}>
                                <div>จำนวนทั้งหมด {showList2.length}</div>
                                <TextField
                                    size="small"
                                    value={search}
                                    onChange={(e) => {
                                        console.log(e)
                                        setSearch(e.target.value)
                                    }}
                                >
                                </TextField>
                            </Box>

                            <TableContainer>
                                <Table sx={{ marginTop: 3, minWidth: '1000px' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>#</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เวลาซักประวัติ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>รหัสนำทาง</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>ห้องตรวจ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>รหัสคลินิก</TableCell>
                                            {/* <TableCell style={{ color: '#743D90', textAlign: 'center' }}>ชื่อคลินิก</TableCell> */}
                                            {/* <TableCell style={{ color: '#743D90', textAlign: 'center' }}>แพทย์</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center' }}>HN</TableCell> */}
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>ชื่อ</TableCell>
                                            <TableCell style={{ color: '#743D90', textAlign: 'center', fontWeight: 'bold' }}>เปลี่ยนห้อง</TableCell>

                                        </TableRow>
                                    </TableHead>
                                    {showList2.filter((item) => {
                                        return (item?.['fname'] + item?.['lname']).includes(search)
                                    }).map((i, idx) => (
                                        <TableBody key={idx}>
                                            <TableRow>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{1 + idx}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['history_inquiry_start'] ? i['history_inquiry_start'] + ' น.' : i['appointment_time'] + ' น.'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['queue'] ? groupCodeLine(i['clinic_code'], i['queue']) : '-'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['room_name'] ? i['room_name'] : '-'}</TableCell>
                                                {/* <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['clinic_code']}</TableCell> */}
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['clinic_name']}</TableCell>
                                                {/* <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['doctor_name'] ? i['doctor_name'] : '-'}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['hn'] ? i['hn'] : '-'}</TableCell> */}
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}>{i['fname'] + ' ' + i['lname']}</TableCell>
                                                <TableCell style={{ color: '#747676', textAlign: 'center' }}><Button variant="contained" onClick={() => { clickChangeRoom(i['_id']) }}>เปลี่ยนห้อง</Button></TableCell>
                                            </TableRow>
                                        </TableBody>
                                    ))}
                                </Table>
                            </TableContainer>

                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                margin: 2
                            }}>
                                <Box sx={{ width: '100px' }}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={numPage2}
                                            onChange={(e) => {
                                                const {
                                                    target: { value },
                                                } = e;
                                                setNumPage2(value)
                                            }}
                                            size='small'
                                            sx={{ width: '100px', fontSize: '14px' }}
                                        >
                                            <MenuItem value={5}>5</MenuItem>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={20}>20</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={maxPage2}
                                        page={page2}
                                        onChange={handlePageChange2}
                                        shape="rounded"
                                        color="primary"
                                    />
                                </Stack>
                            </Box>

                            {openModalChange ?
                                <ModalSetupRoom
                                    onClose={(e) => {
                                        if (e === true) {
                                            // getClinicCheckInAppointment()
                                        }
                                        setOpenModalChange(false)
                                    }}
                                    data={dataChange}
                                    title='เปลี่ยนห้อง'
                                />
                                : null
                            }
                        </>
                        : null
                    }

                </Box>

            </Box>
        </>
    )
}

export default AskOrganizingRoom