import { Box, Button } from "@mui/material";
import React, { useState } from "react";
import AppointmentPatients from "./AppointmentPatients";
import AddAppointment from "./AddAppointment";
import TabQueueSystem from "./queue_system/TabQueueSystem";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../store/menuSlice';
import { useEffect } from "react";

export default function TabConsultDoctor() {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [value, setValue] = useState(0);

    if (!location.state) {
        location.state = { value: 0 }
    }

    useEffect(() => {
        dispatch(menuActive('ปรึกษาแพทย์/พยาบาล'))
    },[])

    return (
        <>
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                gap: 3,
                margin: 3,
                '@media (max-width: 1023px)': {
                    margin: 2,
                    gap: 2
                },
                '@media (max-width: 800px)': {
                    display: 'grid',
                    margin: 1,
                    gap: 1,
                    marginBottom: 3
                }
            }}>
                <Button sx={{
                    '@media (max-width: 600px)': {
                        width: 350
                    }
                }} onClick={() => {
                    setValue(0)
                    navigate("/auth/consult_doctor", { state: { value: 0 } })
                }} variant={location.state.value === 0 ? "contained" : "outlined"} >
                    ผู้ป่วยนัด
                </Button>
                <Button onClick={() => {
                    setValue(1)
                    navigate("/auth/consult_doctor", { state: { value: 1 } })
                }} variant={location.state.value === 1 ? "contained" : "outlined"} >
                    เพิ่มการนัดหมาย
                </Button>
                <Button onClick={() => {
                    setValue(2)
                    navigate("/auth/consult_doctor", { state: { value: 2 } })
                }} variant={location.state.value === 2 ? "contained" : "outlined"}>
                    ติดต่อแพทย์/พยาบาล
                </Button>
            </Box>

            {location.state.value === 0 ?
                <>
                    <AppointmentPatients />
                </> : null
            }

            {location.state.value === 1 ?
                <>
                    <AddAppointment />
                </> : null
            }

            {location.state.value === 2 ?
                <>
                    <TabQueueSystem />
                </> : null
            }

        </>
    )
}