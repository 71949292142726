import React,{ useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// redux
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment/moment';
import { logout } from '../store/authSlice'

const PublicRoute = ({ children }) => {
    const authen = useSelector((state) => state.auth);
    let location = useLocation();
    const accessToken = localStorage.getItem('AccessToken')
    const dispatch = useDispatch()

    useEffect(() => {

        if(authen.isAuthenticated && accessToken){
            // console.log(authen)
            let now = moment().unix()
            let expire = authen.user.ExpireInTimestamp

            if(expire){
                let time = expire - now
                // console.log("---> ",time)
                if(time <= 0){
                    console.log('token หมดอายุ')
                    localStorage.clear()
                    dispatch(logout())
                }else{
                    // console.log('token ยังไม่หมดอายุ')
                }
            }else{
                localStorage.clear()
                dispatch(logout())
            }
        }else{
            console.log('ไม่มี auth')
            // console.log(authen)
        }

    },[location])

    console.log('PublicRoute =>', authen.isAuthenticated)

    return (authen.isAuthenticated && accessToken ? <Navigate to='/auth/home' state={{ from: location }} /> : children);
};

export default PublicRoute;