import { InputAdornment, Box, FormControl, TextField, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Stack, Pagination, Select, MenuItem } from "@mui/material";
import React, { useEffect, useState } from "react";
import * as api from '../../services/ApiDrugDelivery'

import ModalCheckAddress from "../../components/ModalController/ModalCheckAddress";
import LoaderAnimation from '../../components/LoaderAnimation'
import ModalSuccess from '../../components/ModalController/ModalSuccess'
import ModalContactConference from "../../components/ModalController/ModalContactConference";
import ModalCalendar from "../../components/ModalController/ModalCalendar";

import moment from "moment";
import dayjs from 'dayjs';
import buddhistEra from "dayjs/plugin/buddhistEra";
import 'dayjs/locale/th';


import IconButton from '@mui/material/IconButton';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { notifyError, notifyInfo } from "../../components/NotifyToast";
import SentDrug from "./SentDrug";
import SentDrugDone from "./SentDrugDone";
import { useDispatch } from 'react-redux';
import { menuActive  } from '../../store/menuSlice';

export const buttonPrimary = {
    backgroundColor: '#F3EDF7',
    border: '1px solid #743D90',
    padding: '0.5rem 1rem',
    width: 'auto',
    borderRadius: '8px',
    color: '#743D90',
    boxShadow: 'none',
    width: '100%',
    '&:hover': {
        backgroundColor: '#F3EDF7',
        color: '#743D90',
        boxShadow: 'none'
    },
}

export const buttonInPrimary = {
    backgroundColor: '#FBFBFB',
    border: 'none',
    padding: '0.5rem 1rem',
    width: 'auto',
    color: '#747676',
    width: '100%',
    boxShadow: 'none',
    '&:hover': {
        backgroundColor: '#FBFBFB',
        color: '#747676',
        boxShadow: 'none'
    },
}

export default function DrugDelivery() {

    const [namePatient, setNamePatient] = useState('')
    const [pid, setPid] = useState('')
    const [date, setDate] = useState(dayjs(moment(new Date()).format('YYYY-MM-DD')))

    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(false)

    const [dataSystemSent, setDataSystemSent] = useState([])
    const [dataSystemDone, setDataSystemDone] = useState([])

    const [numPage, setNumPage] = useState(5)
    const [maxPage, setMaxPage] = useState(1)
    const [page, setPage] = useState(1)

    const [numPage1, setNumPage1] = useState(5)
    const [maxPage1, setMaxPage1] = useState(1)
    const [page1, setPage1] = useState(1)

    const [disabledDay, setDisabledDay] = useState([])
    const [openCalendar, setOpenCalendar] = useState(false)
    const dispatch = useDispatch()

    useEffect(() => {
        findTelemedicineSent()
        findTelemedicineDone()
        dispatch(menuActive('การจัดส่งยา'))
    }, [])

    useEffect(() => {
        searchData()
    }, [namePatient, pid])

    const findTelemedicineSent = async () => {
        let dateNow = moment(date['$d']).format('YYYY-MM-DD')

        setLoading(true)
        let res = await api.findTelemedicineSent(dateNow)
        setLoading(false)
        if (res) {
            // console.log("res ====> ",res)
            if (res['data']['code'] === 200) {
                setDataSystemSent(res['data']['data'])
                setMaxPage((Math.ceil(res['data']['total'] / numPage)))
            } else {
                setDataSystemSent([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const findTelemedicineDone = async () => {
        let dateNow = moment(date['$d']).format('YYYY-MM-DD')

        setLoading(true)
        let res = await api.findTelemedicineDone(dateNow)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataSystemDone(res['data']['data'])
                setMaxPage1((Math.ceil(res['data']['total'] / numPage1)))
            } else {
                setDataSystemDone([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const optionTime = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: false,
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)
        const formattedTime = date.toLocaleTimeString(thaiLocale, optionTime)

        const newDate = `${formattedDate}  ${formattedTime} น.`
        return newDate
    }

    const convertDateModal = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    const searchData = () => {
        // console.log(namePatient,'-----',pid)
        // console.log(value)

        if (value === 0) {
            // console.log(dataSystemSent)
            return dataSystemSent.filter((item) =>
                (namePatient === '' || item['Name'].includes(namePatient)) &&
                (pid === '' || item['PID'].includes(pid))
            )
        } else {
            // console.log(dataSystemDone)
            return dataSystemDone.filter((item) =>
                (namePatient === '' || item['Name'].includes(namePatient)) &&
                (pid === '' || item['PID'].includes(pid))
            )
        }


    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            {openCalendar &&
                <ModalCalendar
                    onFinished={(paramsState => {
                        if (paramsState === 'eiei') {
                            findTelemedicineSent()
                            findTelemedicineDone()
                        }
                    })}
                    onClose={() => { setOpenCalendar(false) }}
                    value={date ? dayjs(moment(date['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))}
                    onChange={(e) => setDate(e)}
                    disableDay={disabledDay}
                />
            }
            <Box sx={{
                backgroundColor: '#FFFFFF',
                border: '1px solid #E1E3E9',
                borderRadius: 2,
                marginTop: 2,
                padding: 2,
                fontSize: 18,
                '@media (max-width: 1023px)': {
                    padding: 1
                },
                '@media (max-width: 800px)': {
                    marginTop: 1,
                    padding: 1,
                    fontSize: 14,
                }
            }}>
                <div style={{ marginBottom: 10, fontWeight: 'bold' }}>Filters</div>
                <Box sx={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 2fr 2fr 1fr',
                    gridGap: 30,
                    alignItems: 'end',
                    '@media (max-width: 1023px)': {
                        gridTemplateColumns: '1fr 1fr',
                        gridGap: 20,
                    },
                    '@media (max-width: 800px)': {
                        gridTemplateColumns: '1fr',
                        gridGap: 10,
                    }
                }}>
                    <FormControl>
                        <div style={{ color: '#743D90' }} >ค้นหาโดยวันที่</div>
                        <TextField
                            size='small'
                            value={convertDateModal(date['$d'])}
                            disabled
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                        <IconButton onClick={() => {
                                            setOpenCalendar(true)
                                        }}>
                                            <CalendarTodayIcon />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }} />
                    </FormControl>
                    <FormControl>
                        <div style={{ color: '#743D90' }} >ค้นหาโดยชื่อ-นามสกุล</div>
                        <TextField
                            size='small'
                            placeholder={'กรุณาระบุชื่อ-นามสกุลของผู้ป่วย'}
                            value={namePatient}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                setNamePatient(value)
                            }}
                        ></TextField>
                    </FormControl>
                    <FormControl>
                        <div style={{ color: '#743D90' }} >ค้นหาโดยเลขประจำตัวประชาชน</div>
                        <TextField
                            size='small'
                            placeholder={'กรุณาระบุเลขประจำตัวประชาชนของผู้ป่วย'}
                            value={pid}
                            onChange={(event) => {
                                const {
                                    target: { value },
                                } = event;
                                const maxLength = 13;
                                const numericRegex = /^[0-9]*$/;
                                if (value.length <= maxLength && numericRegex.test(value)) {
                                    setPid(value)
                                }
                            }}
                        ></TextField>
                    </FormControl>
                    <div style={{ color: '#747676' }}>จำนวนทั้งหมด {value === 0 ? dataSystemSent.length : dataSystemDone.length} </div>
                </Box>
            </Box>

            <Box sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                fontSize: 18,
                marginTop: 3,
                '@media (max-width: 1023px)': {
                    fontSize: 14,
                },
                '@media (max-width: 800px)': {
                    gridTemplateColumns: '1fr',
                    fontSize: 12,
                }
            }}>
                <Button
                    onClick={() => { setValue(0) }}
                    sx={value === 0 ? buttonPrimary : buttonInPrimary}
                >รายการผู้ป่วยที่ต้องส่งยา</Button>
                <Button
                    onClick={() => { setValue(1) }}
                    sx={value === 1 ? buttonPrimary : buttonInPrimary}
                >รายการผู้ป่วยที่ส่งยาแล้ว</Button>
            </Box>

            {value === 0 ?
                <SentDrug
                    dataSystemSent={dataSystemSent}
                    numPage={numPage}
                    setNumPage={setNumPage}
                    maxPage={maxPage}
                    page={page}
                    setPage={setPage}
                    convertDate={convertDate}
                    searchData={searchData()}
                    namePatient={namePatient}
                    pid={pid}
                /> : null
            }

            {value === 1 ?
                <SentDrugDone
                    dataSystemDone={dataSystemDone}
                    numPage1={numPage1}
                    setNumPage1={setNumPage1}
                    maxPage1={maxPage1}
                    page1={page1}
                    setPage1={setPage1}
                    convertDate={convertDate}
                    searchData={searchData()}
                    namePatient={namePatient}
                    pid={pid}
                />
                : null
            }

        </>
    )
}