import { Typography, Box, FormControl, 
    InputLabel,OutlinedInput,InputAdornment,IconButton,
TextField } from '@mui/material'
import React from 'react'
import { useEffect,useState } from 'react'
import * as api from '../services/public_api'
import LoaderAnimation from '../components/LoaderAnimation';
import { notifyError, notifyInfo, notifySuccess } from '../components/NotifyToast';

//reduce
import { useDispatch, useSelector } from 'react-redux'

//reduce auth
import { user_data as UserData } from '../store/authSlice';
import { menuActive } from '../store/menuSlice';
import PostContent from '../components/PostContent';
import SearchIcon from '@mui/icons-material/Search';
import ModalActiveUser from '../components/ModalActiveUser';
 
function Home() {

    const dispatch = useDispatch()
    const [loading, setLoading] = useState(true)
    const data = useSelector((state) => state.auth)

    useEffect(() => {
        getProfile()
        dispatch(menuActive('หน้าแรก'))
    },[])

    const getProfile = async() => {
        setLoading(true)
        let res = await api.profile()
        setLoading(false)
        if(res){
            // console.log("res ====> ",res)
            if(res['data']['code'] === 200 || res['data']['status']){
                dispatch(UserData(res['data']['data']))
                localStorage.setItem("cid", res['data']['data']['CID'])
                localStorage.setItem("email", res['data']['data']['Email'])
                localStorage.setItem("title_name", res['data']['data']['TitleName'])
                localStorage.setItem("first_name", res['data']['data']['FirstName'])
                localStorage.setItem("last_name", res['data']['data']['LastName'])
                localStorage.setItem("group_role", res['data']['data']['GroupRole'])
                localStorage.setItem("image_url", res['data']['data']['ImageURL'])
                localStorage.setItem("phone", res['data']['data']['PhoneNumber'])
                localStorage.setItem("position", res['data']['data']['Position'])
                localStorage.setItem("hospital_code", res['data']['data']['Permission']['HospitalCode'])
                localStorage.setItem("uid", res['data']['data']['UID'])
                localStorage.setItem("hospital_name", res['data']['data']['Permission']['HospitalName'])
                localStorage.setItem("group_name", res['data']['data']['Permission']['GroupName'])
                localStorage.setItem("department_code", res['data']['data']['Permission']['DepartmentCode'])
                // localStorage.setItem("menu_active", 'หน้าแรก')
            }else{
                notifyInfo(res['data']['message'])
            }
        }else{
            notifyError('ไม่ตอบสนอง')
        }
    }

    const container = {
        backgroundColor: '#fff',
        width:'100%',
        minHeight:'100vh',
        height:'100%',
        padding:'1rem',
        position:'relative',
        // top:'-2rem'
    }
    return (
        <>
            <LoaderAnimation loading={loading} />
            {/* <ModalActiveUser/> */}
            <Box sx={container}>
                    <Box sx={{ margin: '1rem 0 3.5rem 0',textAlign:'center'}}>
                        <TextField 
                            placeholder="ค้นหา" 
                            variant="outlined"
                            size='small'
                            sx={{width: '80%'}}
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                                )
                            }}
                        />
                    </Box>
                    <PostContent/>
                    <PostContent/>
                </Box>
            {/* {
                data['user_data']['Mastodon'] === false ? 
                <Typography component="h5" variant="h5" color={'primary'}>
                    ยินดีต้อนรับ
                </Typography> : 
                
            } */}
         
            
            
        </>
    )
}

export default Home