import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PageHeader from '../../components/PageHeader'
import styles from './ConsultDoctor.module.css'
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import * as api from '../../services/ApiConsultDoctor'
import * as apiDocument from '../../services/ApiDrugDelivery'
import { useLocation } from "react-router-dom";
import LoaderAnimation from "../../components/LoaderAnimation";
import { notifyError, notifyInfo } from "../../components/NotifyToast";
import print from '../../assets/icons/print.svg'

export default function TreatmentHistory() {

    const location = useLocation()
    const [loading, setLoading] = useState(false)

    const [dataTreatment, setDataTreatment] = useState([])

    useEffect(() => {
        // console.log(location.state.PID, location.state.DocNumber)
        findReport()
    }, [])

    const findReport = async () => {
        setLoading(true)
        let res = await api.findReportAll(location.state.PID)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                setDataTreatment(res['data']['data'])
                console.log(res['data']['data'])
            } else {
                setDataTreatment([])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const documentViewer = async (DocNumber) => {
        setLoading(true)
        let res = await apiDocument.documentViewer(DocNumber)
        setLoading(false)
        if (res) {
            if (res['data']['code'] === 200) {
                let url = res['data']['data']['url']
                window.open(url, '_system')
            } else {
                notifyInfo(res['data']['message'])
            }
        } else {
            notifyError('ไม่ตอบสนอง')
        }
    }

    const printTreatmentRecord = (DocNumber) => {
        documentViewer(DocNumber)
    }

    const convertDate = (dataDate) => {
        const date = new Date(dataDate)
        const optionDate = {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: 'UTC',
        }

        const thaiLocale = 'th-TH';
        const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

        const newDate = `${formattedDate}`
        return newDate
    }

    return (
        <>
            <LoaderAnimation loading={loading} />
            <PageHeader title_name={'กลับไปที่ผู้ป่วยนัด'} back_path={'/auth/consult_doctor'} hide_date />

            {dataTreatment.length > 0 ?
                <Box sx={{
                    display: 'flex',
                    gridGap: 20,
                    marginTop: 3,
                    overflow: "hidden",
                    overflowX: "scroll",
                }}>
                    {dataTreatment.map((item, index) => (
                        <>
                            {item['DiagList'].length > 0 ?
                                <Box sx={{
                                    backgroundColor: '#F3EDF7',
                                    borderRadius: 2,
                                    padding: 2,
                                    maxWidth: '380px',
                                    minWidth: '380px',
                                    // overflowY: "auto",
                                    // height: '30vh',
                                    '@media (max-width: 600px)': {
                                        maxWidth: '300px',
                                        minWidth: '300px',
                                    }
                                }} key={index}>
                                    <div className={styles.date} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <div>{convertDate(item['AppointDate'])}</div>
                                        <img src={print} onClick={() => { printTreatmentRecord(item['DocNumber']) }} />
                                    </div>
                                    <Box sx={{
                                        backgroundColor: '#FFFFFF',
                                        borderRadius: 2,
                                        padding: 2,
                                        marginTop: 2
                                    }}>
                                        <div className={styles.key}>บันทึกการรักษา</div>
                                        {item['DiagList'].map((i, idx) => (
                                            <Typography sx={{
                                                my: '10px'
                                            }}>
                                                <div key={idx} className={styles.value} style={{ color: '#747676' }}>{i['Detail']}</div>
                                            </Typography>
                                        ))}

                                        <div className={styles.key}>การจ่ายยา</div>
                                        {item['OrderList'].map((i, idx) => (
                                            <div style={{ margin: '10px 0' }} key={idx}>
                                                <div className={styles.value}>{i['Detail']}</div>
                                                <div className={styles.value} style={{ color: '#747676' }}>{i['Remark']}</div>
                                            </div>
                                        ))}

                                    </Box>
                                </Box>
                                : null
                            }
                        </>

                    ))}
                </Box>
                :
                <div style={{ fontSize: 24, textAlign: 'center', margin: '20%' }}>ไม่พบข้อมูล</div>
            }

        </>
    )
}