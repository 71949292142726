import React, { useEffect, useState } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import { backgroundModal, boxModal, buttomPrimary, buttomSecondary } from './ModalAccessCss'
import { Typography, Box, TextField, Button, FormControl, Select, MenuItem, InputLabel } from '@mui/material'
import * as Api from '../../services/public_api'
import { notifySuccess, notifyError, notifyInfo } from '../../components/NotifyToast'

function ModalAddHospital({ closeModal, submitForm, selectedValue }) {

    const [hospitalList, setHospitalList] = useState([])
    const [SelectedHospital, setSelectedHospital] = useState(null)

    useEffect(() => {
        if (SelectedHospital) {
            console.log(SelectedHospital)
            let { HospitalCode, HospitalName } = SelectedHospital
            selectedValue({
                hospital_code: HospitalCode,
                hospital_name: HospitalName
            })

        }
    }, [SelectedHospital])

    useEffect(() => {
        findHospital()
    }, [])

    const findHospital = async () => {
        let res = await Api.findHospital()
        if (res) {
            if (res['data']['code'] === 200) {
                setHospitalList(res['data']['data'])
            } else {
                return notifyInfo(res['data']['message'])
            }
        } else {
            return notifyError('ระบบไม่ตอบสนอง')
        }
    }

    const handleChangeSelectHospital = (e) => {

        console.log(e.target.value)
        if (e.target.name === 'HospitalCode') {
            setSelectedHospital(e.target.value) // object value
        }
    }

    const handleConfirmAddHospital = () => {
        if (!SelectedHospital) {
            notifyInfo('กรุณาเลือกชื่อสถานะพยาบาล')
            return
        }

        submitForm()
    }
    return (
        <Box sx={backgroundModal}>
            <Box sx={boxModal}>
                <Box sx={{ textAlign: 'end', '&:hover': { cursor: 'pointer' } }} onClick={closeModal}><ClearIcon /></Box>
                <Typography
                    component="p"
                    variant="p"
                    color={'#743D90'}
                    sx={{ fontSize: '18px', fontWeight: 'bold' }}
                >
                    เพิ่มสถานพยาบาลที่ต้องการ ขอคำปรึกษา
                </Typography>
                <hr style={{ margin: '1.5rem 0', width: '100%' }} />
                <Typography
                    component="p"
                    variant="p"
                    color={'#743D90'}
                    sx={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0.5rem' }}
                >
                    เลือกสถานพยาบาล
                </Typography>
                {/* <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="กรุณากรอกชื่อสถานพยาบาล"
                    type='text'
                    name={name}
                    value={value}
                    onChange={onChange}
                /> */}

                <FormControl fullWidth>
                    <InputLabel>เลือกโรงพยาบาล</InputLabel>
                    <Select
                        label='เลือกโรงพยาบาล'
                        name='HospitalCode'
                        renderValue={(selected) => SelectedHospital ? selected.HospitalName : ''}
                        value={SelectedHospital || ''}
                        // error={!SelectedHospital}
                        onChange={handleChangeSelectHospital}
                    >
                        {/* <MenuItem value=" ">เลือกโรงพยาบาล</MenuItem> */}
                        {
                            hospitalList.map((item, index) => (
                                <MenuItem key={index} value={item}>{item['HospitalName']}</MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>
                <Box sx={{ display: 'flex', justifyContent: 'center', gridGap: '1rem', marginTop: '2rem' }}>
                    <Button
                        onClick={closeModal}
                        fullWidth
                        variant="contained"
                        sx={buttomSecondary}
                    >
                        {'ยกเลิก'}
                    </Button>
                    <Button
                        onClick={() => { handleConfirmAddHospital() }}
                        fullWidth
                        variant="contained"
                        sx={buttomPrimary}
                    >
                        {'บันทึก'}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ModalAddHospital