import React, { useContext } from 'react'
import { Typography,Box, IconButton } from "@mui/material"
import { Menu, MenuItem, Sidebar, useProSidebar } from "react-pro-sidebar"
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined'
import AccessibilityNewIcon from '@mui/icons-material/AccessibilityNew';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import VaccinesOutlinedIcon from '@mui/icons-material/VaccinesOutlined';
import { Link, useNavigate } from "react-router-dom"
import {
    RECORD_PATIENT_TITLE,
    OUT_PITENT_TITLE,
    CONSULT_DOCTOR_TITLE,
    DRUG_DELIVERY_TITLE,
    MANAGE_USER_TITLE,
    SOS_TITLE,
    CONSULT_HOSPITAL_TITLE,

    RECORD_PATIENT_PATH,
    OUT_PITENT_PATH,
    CONSULT_PATH,
    DRUG_DELIVERY_PATH,
    MANAGE_USER_PATH,
    SOS_PATH,
    CONSULT_HOSPITAL_PATH

} from "../config/constants"
import { useState, useEffect } from "react"

import icon__delivivry_drug_active from '../assets/icons/delivery-drug-active.svg'
import icon__delivivry_drug_inactive from '../assets/icons/delivery-drug-inactive.svg'

import icon_menu_consult_doctor_active from '../assets/icons/menu-consult-doctor-active.svg'
import icon_menu_consult_doctor_inactive from '../assets/icons/menu-consult-doctor-inactive.svg'

import icon_menu_history_patient_active from '../assets/icons/menu-history-patient-active.svg'
import icon_menu_history_patient_inactive from '../assets/icons/menu-history-patient-inactive.svg'

import icon_menu_manage_user_active from '../assets/icons/menu-manage-user-active.svg'
import icon_menu_manage_user_inactive from '../assets/icons/menu-manage-user-inactive.svg'

import icon_menu_out_patient_active from '../assets/icons/menu-out-patient-active.svg'
import icon_menu_out_patient_inactive from '../assets/icons/menu-out-patient-inactive.svg'

import icon_menu_sos_active from '../assets/icons/sos_phone_active.svg'
import icon_menu_sos_inactive from '../assets/icons/sos_phone_inactive.svg'

import icon_menu_consult_active from '../assets/icons/menu-consult-active.svg' 
import icon_menu_consult_inactive from '../assets/icons/menu-consult-inactive.svg' 

import HomeIcon from '@mui/icons-material/Home';

import * as apiManage from '../services/public_api'
import { MainContext } from '../App';
import { notifyError, notifyInfo, notifySuccess } from '../components/NotifyToast'; 
import styles from './SideNav.module.css'

import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import MenuList from '@mui/material/MenuList';
// import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
// import Typography from '@mui/material/Typography';
import ContentCut from '@mui/icons-material/ContentCut';
import ContentCopy from '@mui/icons-material/ContentCopy';
import ContentPaste from '@mui/icons-material/ContentPaste';
import Cloud from '@mui/icons-material/Cloud';

import ForumIcon from '@mui/icons-material/Forum';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch, useSelector } from 'react-redux';
import { menuActive as ManageMenu } from '../store/menuSlice'

const SideNav = () => {
    const {
        menuState,
        handleOpenPopup
    } = useContext(MainContext)

    // useProSidebar hook to control the sidebar
    const { collapsed, toggleSidebar } = useProSidebar()
    const navigate = useNavigate()

    // Local state for active menu item
    const [activeMenuItem, setActiveMenuItem] = useState('')
    
    const menuActive = useSelector((state) => state.menu_active) 
    const dispatch = useDispatch()

    // method for handle click on menu item
    const handleMenuClick = (menu,path) => {
        // console.log(menu)
        if (menu === 'ประวัติผู้ป่วย') {
            handleOpenPopup({
                actvie : true
            })
        }
        setActiveMenuItem(menu)
        toggleSidebar()
        navigate(path)

        dispatch(ManageMenu(menu))
    }

    const [menu, setMenu] = useState([])

    const menuSidebar = [
        {
            menu: RECORD_PATIENT_TITLE,
            icon_active: icon_menu_history_patient_active,
            icon_inactive: icon_menu_history_patient_inactive,
            path: RECORD_PATIENT_PATH
        },
        {
            menu: OUT_PITENT_TITLE,
            icon_active: icon_menu_out_patient_active,
            icon_inactive: icon_menu_out_patient_inactive,
            path: OUT_PITENT_PATH
        },
        {
            menu: CONSULT_DOCTOR_TITLE,
            icon_active: icon_menu_consult_doctor_active,
            icon_inactive: icon_menu_consult_doctor_inactive,
            path: CONSULT_PATH
        },
        {
            menu: DRUG_DELIVERY_TITLE,
            icon_active: icon__delivivry_drug_active,
            icon_inactive: icon__delivivry_drug_inactive,
            path: DRUG_DELIVERY_PATH
        },
        {
            menu: MANAGE_USER_TITLE,
            icon_active: icon_menu_manage_user_active,
            icon_inactive: icon_menu_manage_user_inactive,
            path: MANAGE_USER_PATH
        },
        {
            menu: SOS_TITLE,
            icon_active: icon_menu_sos_active,
            icon_inactive: icon_menu_sos_inactive,
            path: SOS_PATH
        },
        {
            menu: 'ปรึกษา',
            icon_active: icon_menu_consult_active,
            icon_inactive: icon_menu_consult_inactive,
            path: CONSULT_HOSPITAL_PATH
        },
    ]

    useEffect(() => {
        // showMenu()
        getMenu()

    }, [menuState])

    const getMenu = async () => {
        let res = await apiManage.profile()
        if (res) {
            // console.log("res login ====> ", res)
            let menu_list = res['data']['data']?.['MenuList']
            const newMenu = menuSidebar.filter(item => {
                return menu_list?.find((menuItem) => menuItem['MenuName'] === item['menu'])
            })
            setMenu(newMenu)
        } else {
            setMenu(menuSidebar)
            notifyError('ไม่ตอบสนอง')
        }
    }

    // console.log("Menu ====> ", menu)
    // console.log(menuList)
    // console.log(collapsed)
    // console.log(activeMenuItem)
    
    return (
        <Sidebar
            // style={{ minHeight: "100vh", top: 'auto',zIndex:9999}}
            style={{ 
                minHeight: "100vh", 
                top: 'auto',
                // Index:9991
            }}
            breakPoint="md"
            backgroundColor={'#fff'}
        >
            <br />
            {/* <br /> */}
           
            <Menu
                menuItemStyles={{
                    button: ({ active }) => {
                        return {
                            backgroundColor: active ? '#743D90' : 'transparent',
                            color: active ? '#ffffff' : '#747676',
                            fontWeight: active ? 'bold' : 'normal',
                            borderRadius: '0.5rem',
                            margin: '0.15rem',
                            transition: 'background-color 0.3s ease',
                            '&:hover' : {
                                backgroundColor: '#743D90', 
                                color: '#fff',
                            },
                        }
                    }
                }}
                >
                <MenuItem 
                        active={menuActive.menu === 'หน้าแรก'}
                        icon={<HomeIcon sx={{color: menuActive.menu === 'หน้าแรก' ? '#fff' : '#747676'}}/>}
                        onClick={() => handleMenuClick('หน้าแรก','/auth/home')}
                >
                            <p  >หน้าแรก</p>
                </MenuItem>
                {
                    menu.map((item,idex) => (
                        <MenuItem
                          key={idex}
                          active={menuActive.menu === item['menu']}
                          icon={<img src={menuActive.menu === item['menu'] ? item['icon_active'] : item['icon_inactive']} alt="" />}
                          onClick={() => handleMenuClick(item['menu'], item['path'])}
                        >
                          <p>{item['menu']}</p>
                        </MenuItem>
                    ))
                }
                
            </Menu >

           
        </Sidebar >
    )
}

export default SideNav