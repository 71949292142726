import React from 'react'
import { Box, Button, TableContainer,Table,TableHead,TableRow,TableCell,TableBody,Stack,Pagination,
         TextField,Typography,FormControl,InputLabel,Select,MenuItem,InputAdornment,IconButton } from '@mui/material'
import { boxContent, boxSave, buttonPrimary,contentBox,contentSave,menuTable,boxPagination } from './mainCss'
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import sendPdf from '../../assets/icons/send-pdf.svg'
import * as apiManage from '../../services/Manage/ApiManageUser'
import { useEffect } from 'react';
import { useState } from 'react';
import LoaderAnimation from '../../components/LoaderAnimation';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import thLocale from 'dayjs/locale/th';

import { notifyError, notifyInfo , notifyWarning } from '../../components/NotifyToast';
import ModalCalendar from '../../components/ModalController/ModalCalendar';
import moment from 'moment-timezone';
import 'moment/locale/th';

dayjs.extend(utc);
dayjs.extend(timezone);

function SavePermission() {
    const [dataSave,setDataSave] = useState([])
    const [loading, setLoading] = useState(true)
    const [disabledDay, setDisabledDay] = useState([])

    const [search,setSearch] = useState({
        name:'',
        date_start:'',
        date_end: ''
    })

    const [pagination,setPagination] = useState({
        totalPage:'',
        offSet:'',
        total:''
    })

    const [page,setPage] = useState(0)
    const [limit,setLimit] = useState(5)
    const [sort,setSort] = useState('desc')
    const  [isSearch,setIsSearch] = useState(false)

    const [openCalendar, setOpenCalendar] = useState(false) 
    const [typedate,setTypeDate] = useState('')

    useEffect(() => {
        saveSystem()
    },[page,isSearch])

    useEffect(() => {
        saveSystem()
        setPage(0)
    },[limit])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const th_save = [
        {
            id: 'th_title01',
            th_name: '#',
        },
        {
            id: 'th_title02',
            th_name: 'ผู้ใช้งาน',
        },
        {
            id: 'th_title03',
            th_name: 'บันทึกการใช้งาน',
        },
        {
            id: 'th_title04',
            th_name: 'วันที่และเวลา',
        },
        {
            id: 'th_title05',
            th_name: 'สถานนะ',
        }
    ]

    const saveSystem = async() => {
        setLoading(true)
        let res = await apiManage.saveSystem(
            page === 0 ? page : page-1,
            limit,
            search['date_start'] ? moment(search['date_start']['$d']).format('YYYY-MM-DD') : '',
            search['date_end'] ? moment(search['date_end']['$d']).format('YYYY-MM-DD') : '',
            search['name'],
            sort
        )
        setLoading(false)
        if(res){
            if(res['data']['code'] === 200 ){
                // console.log("===> ",res['data'])
                setDataSave(res['data']['data'])
                setPagination({
                    totalPage: res['data']['paging']['totalPage'],
                    offSet: res['data']['paging']['offSet'],
                    total: res['data']['paging']['total']
                })
            }else{
                setDataSave([])
                console.log(res['data']['message'])
            }
        }else{
            notifyError('ระบบไม่ตอบสนอง')
        }
    }

    const handleOpenCalendar = (type_date) => {
        setTypeDate(type_date)
        setOpenCalendar(true)
    }

    const convertDate = (dataDate) => {
        const utcDateTime = moment.utc(dataDate, 'YYYY-MM-DD HH:mm:ss');

        const thaiDateTime = utcDateTime.tz('Asia/Bangkok');
        const formattedDate = thaiDateTime.locale('th').add(543, 'year').format('D MMMM YYYY HH:mm น.');
        
        return formattedDate
   }

   const convertDateSearch = (dataDate) => {
    const date = new Date(dataDate)
    const optionDate = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    }
    const thaiLocale = 'th-TH';
    const formattedDate = date.toLocaleDateString(thaiLocale, optionDate)

    const newDate = `${formattedDate}`
    return newDate
  }

   const searchData = () => {
        // console.log("data search ====> ",search)
        // console.log(moment(search['date_start']['$d']).format('YYYY-MM-DD'))
        // console.log(moment(search['date_end']['$d']).format('YYYY-MM-DD'))

        // if(!search['name']){
        //     return notifyWarning('กรุณากรอกชื่อ')
        // }
        // if(!search['date_start']){
        //     return notifyWarning('กรุณากรอกวันที่เริ่ม')
        // }
        // if(!search['date_end']){
        //     return notifyWarning('กรุณากรอกวันสิ้นสุด')
        // }
        setIsSearch(!isSearch)
        setSort('asc')
   }

  return (
    <>
        <LoaderAnimation loading={loading} />
        {openCalendar &&
            <ModalCalendar
                onClose={() => { setOpenCalendar(false) }}
                value={
                    typedate === 'start' ? 
                    search['date_start'] ? dayjs(moment(search['date_start']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                    :
                    search['date_end'] ? dayjs(moment(search['date_end']['$d']).format('YYYY-MM-DD')) : dayjs(moment(new Date()).format('YYYY-MM-DD'))
                }
                onChange={(e) => 
                    {
                        typedate === 'start' ? 
                        setSearch({...search,['date_start']:e})
                        :
                        setSearch({...search,['date_end']:e})
                    }
                }
                disableDay={disabledDay}
                onFinished={(paramsState => {
                    if (paramsState === 'eiei') {
                        // saveSystem()
                    }
                })}
            />
        }
        <Box sx={contentBox}>
            <Box sx={boxContent}>
               <Box sx={contentSave}>
                    <Box sx={boxSave}>
                        <Typography 
                            component="p" 
                            variant="p" 
                            sx={{marginBottom:'0.5rem',fontWeight:'bold'}}
                        >
                            ชื่อผู้ใช้งาน
                        </Typography>
                        <TextField 
                            fullWidth  
                            size="small"
                            placeholder="ชื่อผู้ใช้งาน" 
                            variant="outlined"  
                            value={search['name']}
                            onChange={(e) => {
                                setSearch({...search,['name'] : e.target.value})
                                setSort('asc')
                            }}
                        />
                    </Box>
                    <Box sx={boxSave}>
                        <Typography 
                            component="p" 
                            variant="p" 
                            sx={{marginBottom:'0.5rem',fontWeight:'bold'}}
                        >
                                วันที่
                            </Typography>

                            <TextField size='small'
                                value={search['date_start'] ? convertDateSearch(search['date_start']['$d']) : 'เลือกวันที่'}
                                disabled
                                fullWidth
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                    <IconButton onClick={() => {
                                        handleOpenCalendar('start')
                                    }}>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                    </InputAdornment>
                                )
                                }}
                            />
                        {/* <LocalizationProvider dateAdapter={AdapterDayjs} locale={thLocale}>
                                <DatePicker  
                                    label="เลือกวันที่"  
                                    sx={{width:'100%'}} 
                                    onChange={(time) => handleOnChangeDate(time,'date_start')}
                                />
                        </LocalizationProvider> */}
                    </Box>
                    <Box sx={boxSave}>
                        <Typography 
                            component="p" 
                            variant="p" 
                            sx={{marginBottom:'0.5rem',fontWeight:'bold'}}
                        >
                            ถึงวันที่
                        </Typography>

                        <TextField size='small'
                                value={search['date_end'] ? convertDateSearch(search['date_end']['$d']) : 'เลือกวันที่'}
                                disabled
                                fullWidth
                                InputProps={{
                                endAdornment: (
                                    <InputAdornment>
                                    <IconButton onClick={() => {
                                        handleOpenCalendar('end')
                                    }}>
                                        <CalendarTodayIcon />
                                    </IconButton>
                                    </InputAdornment>
                                )
                                }}
                        />

                        {/* <LocalizationProvider dateAdapter={AdapterDayjs} locale={thLocale}>
                                <DatePicker  
                                    label="เลือกวันที่" 
                                    sx={{width:'100%'}} 
                                    onChange={(time) => handleOnChangeDate(time,'date_end')}
                                />
                        </LocalizationProvider> */}
                    </Box>
                    <Button 
                        sx={buttonPrimary}
                        onClick={() => searchData()}
                    >
                        {<><SearchIcon sx={{marginRight:'1rem'}}/>{'ค้นหา'}</>}
                    </Button>
                </Box>
            </Box>
        </Box>

        <Box sx={contentBox}>
            <Box sx={menuTable}>
                <Typography component="span" variant="span" sx={{alignSelf:'center'}}>{`จำนวนทั้งหมด ${pagination['total']}`}</Typography>
                {/* <Button sx={buttonPrimary}>
                    {<><img src={sendPdf} alt=''/><Typography component="p" variant="p"  sx={{marginLeft:'1rem'}}>{'ส่งออกเอกสาร'}</Typography></>}
                </Button> */}
            </Box>
            <TableContainer sx={{borderTop:'1px solid rgba(224, 224, 224, 1)'}}>
                <Table sx={{ minWidth: 1000 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            { th_save.map((th,index) => (
                                <TableCell key={th['id']} align="center" sx={index === 0 ? {width:'10%',fontWeight: '600'} :{fontWeight: '600'}}>{th['th_name']}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            dataSave.length === 0 ?
                            <>
                                <TableRow>
                                    <TableCell 
                                        colSpan={12}
                                        align='center'
                                    >
                                        <Typography 
                                        component={"p"}
                                        sx={{margin:'1rem 0'}}
                                        >
                                            ไม่พบข้อมูล
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            </> : 
                            <>
                             {
                                dataSave.map((data,index) => (
                                    <TableRow key={data['ID']}>
                                        <TableCell align="center">{page==0? (page+1) + index : limit * (page - 1) + 1 + index}</TableCell>
                                        <TableCell >{data['Name'] === ' ' || !data['Name'] ? "-" : data['Name']}</TableCell>
                                        <TableCell>{data['Remark']}</TableCell>
                                        <TableCell align='center'>{ convertDate(data['CreatedDate'])}</TableCell>
                                        <TableCell align='center'>{data['DocStatus'] === 'W' ? "สำเร็จ" : "ไม่สำเร็จ"}</TableCell>
                                    </TableRow>
                                ))
                             }
                            </>
                            
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <Box sx={boxPagination}>
                <Box sx={{width:'100px'}}>
                    <FormControl fullWidth size='small'>
                        <Select 
                              value={limit}
                              onChange={(e) => {
                                  const {
                                      target: { value },
                                  } = e;
                                  setLimit(value)
                              }}
                        >
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                            <MenuItem value={20}>20</MenuItem>
                        </Select> 
                    </FormControl>
                </Box>
                <Stack spacing={2}>
                    <Pagination 
                        count={dataSave.length === 0 ? 0 : (Math.ceil(pagination['total']/limit))}
                        page={
                            page === 0 ? 
                            page + 1 : page
                        }
                        onChange={handlePageChange}
                        shape="rounded" color="primary"
                    />
                </Stack>
            </Box>
        </Box>
    </>
    
  )
}

export default SavePermission